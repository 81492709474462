export default {
  events: {},
  options: {
    page: 1,
    itemsPerPage: 25,
    sortBy: ['eventdate'],
    sortDesc: [true],
  },
  loading: true,
  serverItemsLength: 0,
  itemsPerPageOptions: [25, 50, 100],
  filtersExpanded: undefined,
  filterItemsFetched: false,
  filters: [
    {
      name: 'subscribed',
      type: 'select',
      label: 'Core.Filters.subscriptionState.label',
      itemText: 'text',
      itemValue: 'value',
      items: [
        { value: true, text: 'Core.Filters.subscriptionState.subscribed', translate: true },
        { value: false, text: 'Core.Filters.subscriptionState.unsubscribed', translate: true },
      ],
      value: null,
      default: null,
    },
    {
      name: 'topic',
      type: 'multiselect',
      label: 'Core.Filters.topic.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'global_issue',
      type: 'multiselect',
      label: 'Core.Filters.globalIssue.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'issue',
      type: 'multiselect',
      label: 'Core.Filters.issue.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'eventdate_past',
      type: 'select',
      label: 'Core.Filters.eventdatePast.label',
      itemText: 'text',
      itemValue: 'value',
      items: [
        { value: '1week', text: 'Core.Filters.eventdatePast.1week', translate: true },
        { value: '2weeks', text: 'Core.Filters.eventdatePast.2weeks', translate: true },
        { value: '1month', text: 'Core.Filters.eventdatePast.1month', translate: true },
      ],
      value: '',
      default: '',
    },
    {
      name: 'event_type',
      type: 'treeselect',
      label: 'Core.Filters.eventType.label',
      itemText: 'name',
      itemValue: 'id',
      itemChildren: 'children',
      items: [],
      itemsFlat: [],
      value: [],
      default: [],
    },
    {
      name: 'state',
      type: 'multiselect',
      label: 'Core.Filters.state.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'next_state',
      type: 'multiselect',
      label: 'Core.Filters.nextState.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'type',
      type: 'multiselect',
      label: 'Core.Filters.type.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'political_level',
      type: 'multiselect',
      label: 'Core.Filters.politicalLevel.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
  ],
}
