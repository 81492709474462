<template>
  <v-snackbar
    v-model="show"
    :top="true"
    :color="color"
  >
    {{ message }}
    <template v-slot:action>
      <v-btn
        icon
        @click="show = false"
      >
        <v-icon small>$vuetify.icons.timesCircle</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  data () {
    return {
      show: false,
      message: '',
      color: '',
    }
  },
  created: function () {
    this.unsubscribe = this.$store.watch(state => state.app.snack, (snack) => {
      if (snack !== null) {
        this.show = true
        this.message = snack.translate ? this.$t(snack.message) : snack.message
        this.color = snack.color
        this.$store.commit('app/setSnack', null)
      }
    })
  },
  beforeDestroy () {
    this.unsubscribe()
  },
}
</script>
