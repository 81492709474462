<template>
  <v-row dense>
    <v-col
      cols="12"
      class="text-center"
    >
      <v-btn
        tile
        color="primary"
        class="ma-1"
        @click="onApplyClick"
      >
        {{ $t('Core.FilterButtons.apply') }}
      </v-btn>
      <v-btn
        text
        tile
        class="ma-1"
        @click="onResetClick"
      >
        {{ resetLabel }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FilterButtons',
  props: {
    resetLabel: {
      type: String,
      required: true,
    },
  },
  methods: {
    onApplyClick () {
      this.$emit('apply')
    },
    onResetClick () {
      this.$emit('reset')
    },
  },
}
</script>
