export default {
  events: state => state.events,
  loading: state => state.loading,
  activeFiltersCount: state => state.filters.reduce((count, filter) => {
    if (Array.isArray(filter.value)) {
      count += filter.value.length
    } else {
      if (filter.value !== undefined && filter.value !== null && filter.value !== '') {
        count++
      }
    }
    return count
  }, 0),
}
