import { WIDGET_TYPE_MAP } from "@/utils/widgets";
import Vue from 'vue'

export default {
  SET_AVAILABLE_WIDGETS (state, availableWidgets) {
    state.availableWidgets = availableWidgets
  },
  SET_AVAILABLE_WIDGETS_LOADING (state, loading) {
    state.availableWidgetsLoading = loading
  },
  SET_AVAILABLE_WIDGETS_LOADED (state, loaded) {
    state.availableWidgetsLoaded = loaded
  },
  SET_ACTIVE_WIDGETS (state, activeWidgets) {
    state.activeWidgets = activeWidgets
  },
  SET_ACTIVE_WIDGETS_LOADING (state, loading) {
    state.activeWidgetsLoading = loading
  },
  SET_ACTIVE_WIDGETS_LOADED (state, loaded) {
    state.activeWidgetsLoaded = loaded
  },
  SET_AVAILABLE_WIDGET_LOADING (state, { widgetId, loading }) {
    const widget = state.availableWidgets.find((widget) => widget.id === widgetId)
    if (widget) {
      widget.loading = loading
    }
  },
  SET_WIDGET_LOADING (state, { widgetId, loading }) {
    if (loading) {
      if (!state.widgetsLoading.includes(widgetId)) {
        state.widgetsLoading = [
          ...state.widgetsLoading,
          widgetId,
        ]
      }
    } else {
      state.widgetsLoading = state.widgetsLoading.filter((currentWidgetId) => currentWidgetId !== widgetId)
    }
  },
  SET_WIDGET_DATA (state, { widgetId, data }) {
    Vue.set(state.widgetsData, widgetId, data);
  },
  SET_AFFAIR_SUBSCRIBED (state, { affairId, subscribed }) {
    // Get ids of all affair widgets
    const affairWidgetIds = state.activeWidgets
      .filter((widget) => widget.widget_type_id === WIDGET_TYPE_MAP.LATEST_AFFAIRS)
      .map((widget) => widget.id)

    affairWidgetIds.forEach((affairWidgetId) => {
      if (!state.widgetsData[affairWidgetId]) {
        return
      }

      const affair = state.widgetsData[affairWidgetId].find(affair => affair.id === affairId)
      if (affair) {
        affair.subscribed = subscribed
      }
    })

    // Get ids of all next events widgets
    const nextEventsWidgetIds = state.activeWidgets
      .filter((widget) => widget.widget_type_id === WIDGET_TYPE_MAP.NEXT_EVENTS)
      .map((widget) => widget.id)

    nextEventsWidgetIds.forEach((nextEventsWidgetId) => {
      if (!state.widgetsData[nextEventsWidgetId]) {
        return
      }

      const eventsWithRelatedAffair = state.widgetsData[nextEventsWidgetId].filter(event => event.affair.id === affairId)
      eventsWithRelatedAffair.forEach((event) => {
        event.affair.subscribed = subscribed
      })
    })
  },
}
