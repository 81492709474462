<template>
  <div
    class="v-tree-select-filter v-text-field"
  >
    <div class="v-input__control">
      <v-menu
        max-height="300"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs, value: open }">
          <div
            role="button"
            v-bind="attrs"
            v-on="on"
            class="v-tree-select-filter-button d-flex justify-space-between"
            :class="{ 'is-active': open }"
          >
            <div>
              <div class="my-1">{{ $t(filter.label) }}</div>
              <v-chip
                v-for="(selectedOptionId, index) in value"
                :key="index"
                small
                close
                class="ma-1"
                :color="filter.colorizeItem ? getOptionColorByOptionId(selectedOptionId) : undefined"
                :text-color="filter.colorizeItem ? getOptionTextColorByOptionId(selectedOptionId) : undefined"
                @click:close="() => removeTreeselectOption(selectedOptionId)"
              >
                {{ getOptionLabelByOptionId(selectedOptionId) }}
              </v-chip>
            </div>
            <v-icon
              class="v-tree-select-filter-icon-append px-1"
              :color="open ? 'primary': null"
            >
              $vuetify.icons.dropdown
            </v-icon>
          </div>
        </template>
        <v-sheet>
          <v-treeview
            v-model="value"
            :active.sync="value"
            :items="filter.items"
            :item-key="filter.itemKey"
            :item-text="filter.itemText"
            :item-children="filter.itemChildren"
            selectable
            activatable
            open-on-click
            multiple-active
          >
            <template v-if="filter.colorizeItem" v-slot:prepend="{ item }">
              <div
                v-if="item.children.length === 0"
                class="v-tree-select-filter-prepend-color"
                :style="{ backgroundColor: item.color ? item.color : null }"
              />
            </template>
          </v-treeview>
        </v-sheet>
      </v-menu>
    </div>
  </div>
</template>

<script>
import { getContrastColor } from '@/utils/colorHelper';

const arrayCompare = (arr1, arr2) => {
  return JSON.stringify(arr1) === JSON.stringify(arr2)
}

export default {
  name: 'TreeselectFilter',
  props: {
    filter: {
      type: Object,
      required: true,
    },
  },
  computed: {
    value: {
      get () {
        return this.filter.value
      },
      set (value) {
        if (!arrayCompare(this.value, value)) {
          this.$emit('input', value)
        }
      },
    },
  },
  methods: {
    getOptionLabelByOptionId (optionId) {
      const option = this.findOptionByOptionId(optionId)
      return option ? option[this.filter.itemText] : optionId
    },
    getOptionColorByOptionId (optionId) {
      const option = this.findOptionByOptionId(optionId)
      return option && option.color ? option.color : 'primary'
    },
    getOptionTextColorByOptionId (optionId) {
      const option = this.findOptionByOptionId(optionId)
      return option && option.color ? getContrastColor(option.color) : undefined
    },
    findOptionByOptionId (optionId) {
      return this.filter.itemsFlat.find((item) => item[this.filter.itemValue] === optionId)
    },
    removeTreeselectOption (optionId) {
      this.value = this.value.filter((v) => v !== optionId)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_variables.scss';

.v-tree-select-filter {
  border-bottom: solid 1px rgba(0, 0, 0, 0.42);

  .v-icon {
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1), visibility 0s;
  }

  .v-tree-select-filter-button {
    &.is-active {
      .v-tree-select-filter-icon-append.v-icon {
        transform: rotate(
            180deg
        );
      }
    }
  }
}

.v-tree-select-filter-prepend-color {
  background-color: $brand-primary;
  width: 20px;
  height: 20px;
}
</style>
