export default {
  events: state => state.events.data,
  event: state => id => state.events.data.find(item => item.id === +id),
  loading: state => state.loading,
  serverItemsLength: state => state.serverItemsLength,
  itemsPerPageOptions: state => state.itemsPerPageOptions,
  activeFiltersCount: state => state.filters.reduce((count, filter) => {
    if (Array.isArray(filter.value)) {
      count += filter.value.length
    } else {
      if (filter.value !== undefined && filter.value !== null && filter.value !== '') {
        count++
      }
    }
    return count
  }, 0),
}
