export default {
  setUser (state, user) {
    state.user = user
  },
  setLoading (state, loading) {
    state.loading = loading
  },
  SET_OTHER_USERS (state, otherUsers){
    state.otherUsers = otherUsers
  },
}
