export default {
  affairs: state => state.affairs.data,
  affair: state => affairId => {
    const affair = state.affairs.data.find(affair => {
      return affair.id === affairId
    })
    if (affair) {
      return affair
    }
    return {}
  },
  affairLoading: state => state.affairLoading,
  loading: state => state.loading,
  serverItemsLength: state => state.serverItemsLength,
  itemsPerPageOptions: state => state.itemsPerPageOptions,
  noteTypes: state => state.noteTypes,
  activeFiltersCount: state => state.filters.reduce((count, filter) => {
    if (Array.isArray(filter.value)) {
      count += filter.value.length
    } else {
      if (filter.value !== undefined && filter.value !== null && filter.value !== '') {
        count++
      }
    }
    return count
  }, 0),
}
