<template>
  <v-btn
    :title="affair.subscribed ? $t('Core.SubscribeButton.unsubscribe') : $t('Core.SubscribeButton.subscribe')"
    small
    icon
    class="subscribe ma-1"
    :loading="loading"
    :disabled="loading"
    @click.prevent.stop="toggleSubscription()"
    @mousedown.stop
    @touchstart.native.stop
  >
    <v-icon
      :color="affair.subscribed ? 'primary' : undefined"
      small
    >
      $vuetify.icons.bell
    </v-icon>
  </v-btn>
</template>

<script>
import { mapMutations, mapActions } from 'vuex'

export default {
  name: 'SubscribeButton',
  props: {
    affair: {
      type: Object,
      default () {
        return {}
      },
    },
  },
  data () {
    return {
      loading: false,
    }
  },
  methods: {
    ...mapMutations('app', ['setSnack']),
    ...mapActions('affairs', ['subscribeMe', 'unsubscribeMe']),
    async toggleSubscription () {
      this.loading = true
      if (this.affair.subscribed) {
        const success = await this.unsubscribeMe(this.affair.id)
        if (success) {
          this.$emit('unsubscribed')
          this.setSnack({
            message: this.$t('Core.SubscribeButton.unsubscribedSnack', { title: this.affair.title }),
            color: 'success',
          })
        }
      } else {
        const success = await this.subscribeMe(this.affair.id)
        if (success) {
          this.$emit('subscribed')
          this.setSnack({
            message: this.$t('Core.SubscribeButton.subscribedSnack', { title: this.affair.title }),
            color: 'success',
          })
        }
      }
      this.loading = false
    },
  },
}
</script>
