export const permissions = {
  AFFAIRS: 'affairs',
  AFFAIRS_SUBSCRIBERS: 'affairs_subscribers',
  AFFAIRS_ISSUES: 'affairs_issues',
  EVENTS: 'events',
  ISSUES: 'issues',
  GLOBAL_ISSUES: 'global_issues',
  SUBSCRIBERS: 'subscribers',
  COMMENTS: 'comments',
  ICS: 'ics',
}
