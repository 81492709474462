<template>
  <v-form @submit.prevent="saveQueryToStore()">
    <v-text-field
      v-model="query"
      clearable
      single-line
      hide-details
      :label="$t('Core.Search.placeholder')"
      prepend-inner-icon="$vuetify.icons.search"
      class="mb-2"
      autocapitalize="none"
      @click:clear="clear()"
    />
  </v-form>
</template>

<script>
export default {
  props: {
    module: {
      type: String,
      required: true,
    },
  },
  computed: {
    query: {
      get () {
        return this.$store.state[this.module].query
      },
      set (value) {
        this.$store.commit(`${this.module}/SET_QUERY`, value)
      },
    },
  },
  methods: {
    saveQueryToStore () {
      this.$emit('update', this.query)
    },
    clear () {
      this.$store.commit(`${this.module}/RESET_QUERY`)
      this.$emit('update', this.query)
    },
  },
}
</script>
