<template>
  <v-dialog
    v-model="dialog"
    max-width="450"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot :on="on" :attrs="attrs"></slot>
    </template>
    <v-card>
      <v-card-text
        class="pt-6"
      >
        <v-text-field
          :label="$t('Core.RichTextEditor.linkLabel')"
          v-model="linkValue"
          :hint="$t('Core.RichTextEditor.linkHint')"
          outlined
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="currentLink !== linkValue || !currentLink"
          :disabled="currentLink === linkValue"
          text
          color="primary"
          @click="submit"
        >
          {{ $t('Core.RichTextEditor.save') }}
        </v-btn>
        <v-btn
          v-if="currentLink === linkValue && currentLink"
          color="error"
          text
          @click="remove"
        >
          {{ $t('Core.RichTextEditor.remove') }}
        </v-btn>

        <v-btn
          text
          @click="reset"
        >
          {{ $t('Core.RichTextEditor.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    currentLink: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
      linkValue: '',
    }
  },
  watch: {
    currentLink(value) {
      this.linkValue = value;
    },
  },
  methods: {
    submit() {
      this.$emit('input', this.linkValue);
      this.reset();
    },
    remove() {
      this.$emit('remove');
      this.reset();
    },
    reset() {
      this.dialog = false;
      this.linkValue = this.currentLink;
    },
  },
}
</script>
