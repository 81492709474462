var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"event-list",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.optionsPassthrough,"server-items-length":_vm.serverItemsLength,"loading":_vm.loading,"footer-props":{
    itemsPerPageOptions: _vm.itemsPerPageOptions,
  },"expanded":_vm.expanded,"mobile-breakpoint":0,"item-class":_vm.getItemClass,"item-key":"id","show-expand":""},on:{"update:options":function($event){_vm.optionsPassthrough=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" "+_vm._s(_vm.loadingText)+" ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.noDataText)+" ")]},proxy:true},{key:"item.data-table-expand",fn:function(ref){
  var expand = ref.expand;
  var item = ref.item;
  var isExpanded = ref.isExpanded;
return [(!item.affair.is_blanco)?_c('v-icon',{staticClass:"v-data-table__expand-icon",class:{ 'v-data-table__expand-icon--active': isExpanded },on:{"click":function($event){$event.stopPropagation();return (function () { return expand(!isExpanded); })($event)}}},[_vm._v(" $vuetify.icons.expand ")]):_vm._e()]}},{key:"item.eventdate",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$options.filters.getFormattedEventdate(item.eventdate, item.enddate))+" ")]}},{key:"item.event_type_name",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.event_type ? item.event_type.name : '-')+" ")]}},{key:"item.affair_number",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(!item.affair.is_blanco && item.affair.affair_number ? item.affair.affair_number : '-')+" ")]}},{key:"item.affair_title",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(!item.affair.is_blanco && item.affair.title ? item.affair.title : '-')+" ")]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"actions"},[(!item.affair.is_blanco)?_c('core-subscribe-button',{attrs:{"affair":item.affair},on:{"subscribed":function($event){return _vm.onSubscribed(item.affair.id)},"unsubscribed":function($event){return _vm.onUnsubscribed(item.affair.id)}}}):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
  var headers = ref.headers;
  var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(item.author)?_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Core.EventList.author')}},[_vm._v(" "+_vm._s(item.author.name)+" ")]):_vm._e(),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Core.EventList.description')}},[_c('span',{domProps:{"innerHTML":_vm._s(item.description ? _vm.$options.filters.nl2br(item.description) : '-')}})]),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Core.EventList.affairTopics')}},[(item.affair.topics && item.affair.topics.length > 0)?_c('div',_vm._l((item.affair.topics),function(topic){return _c('v-chip',{key:topic.id,staticClass:"ma-1"},[_vm._v(" "+_vm._s(topic.name)+" ")])}),1):[_vm._v("-")]],2),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Core.EventList.affairGlobalIssues')}},[(item.affair.global_issues && item.affair.global_issues.length > 0)?_c('div',_vm._l((item.affair.global_issues),function(globalIssue){return _c('v-chip',{key:globalIssue.id,staticClass:"ma-1"},[_vm._v(" "+_vm._s(globalIssue.name)+" ")])}),1):[_vm._v("-")]],2),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Core.EventList.affairIssues')}},[(item.affair.issues && item.affair.issues.length > 0)?_c('div',_vm._l((item.affair.issues),function(issue){return _c('v-chip',{key:issue.id,staticClass:"ma-1"},[_vm._v(" "+_vm._s(issue.name)+" ")])}),1):[_vm._v("-")]],2),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Core.EventList.affairType')}},[_vm._v(" "+_vm._s(item.affair.affair_type ? item.affair.affair_type.name : '-')+" ")]),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Core.EventList.affairState')}},[_vm._v(" "+_vm._s(item.affair.state ? item.affair.state.name : '-')+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }