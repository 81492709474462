<template>
  <widgets-base
    :title="widget.title"
    :action="{ label: widget.action_label, to: { name: 'affairs', query: queryParams } }"
    :loading="loading"
  >
    <template slot="actions" v-if="widget.show_search">
      <widgets-components-latest-affairs-search
        :loading="loading"
        :query="query"
        @update="onQueryChange"
        @reset="onQueryReset"
      ></widgets-components-latest-affairs-search>
    </template>
    <v-list
      class="latest-affairs-list pa-0"
      two-line
    >
      <template v-if="loading">
        <v-list-item
          v-for="count in widget.item_count || 10"
          :key="count"
          class="pa-0"
        >
          <v-list-item-content class="pa-0">
            <v-skeleton-loader
              type="list-item-two-line"
            />
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item
        v-else-if="affairs.length > 0"
        v-for="affair in affairs"
        :key="affair.id"
        :to="{ name: 'affair-detail', params: { id: affair.id } }"
      >
        <v-list-item-content>
          <div v-if="affair.submission_date || affair.affair_type" class="d-flex mb-2 align-center overflow-hidden">
            <div class="font-weight-bold mr-2" v-if="affair.submission_date">{{ $options.filters.formatDate(affair.submission_date) }}</div>
            <div class="overline text-truncate" v-if="affair.affair_type">{{ affair.affair_type.name }}</div>
          </div>
          <div class="text-truncate">
            <v-chip
              v-if="affair.affair_number"
              color="primary"
              class="mr-2"
              small
            >{{ affair.affair_number }}</v-chip>
            <span>{{ affair.title }}</span>
          </div>
        </v-list-item-content>
        <v-list-item-action class="flex-shrink-0">
          <core-subscribe-button
            :affair="affair"
            @subscribed="onSubscribed(affair.id)"
            @unsubscribed="onUnsubscribed(affair.id)"
          />
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-content>
          {{ $t('Dashboard.widgets.latestAffairs.empty') }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </widgets-base>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'LatestAffairsWidget',
  async mounted () {
    await this.getLatestAffairs({ widget: this.widget, q: this.query })
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    affairs: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    queryParams () {
      const queryParams = {}
        if (this.widget.affair_types && this.widget.affair_types.length > 0) {
          queryParams.type = this.widget.affair_types.map((affairType) => affairType.id).join(',')
        }
        if (this.widget.subscribed) {
          queryParams.subscribed = true
        }
        if (this.widget.show_search && this.query) {
          queryParams.q = this.query
        }
      return queryParams
    },
    query () {
      return this.widget.pivot.settings?.q
    },
  },
  methods: {
    ...mapActions('widgets', [
      'getLatestAffairs',
      'updateWidgetSettings',
    ]),
    ...mapMutations('widgets', [
      'SET_AFFAIR_SUBSCRIBED',
    ]),
    onSubscribed (affairId) {
      this.SET_AFFAIR_SUBSCRIBED({ affairId, subscribed: true })
    },
    onUnsubscribed (affairId) {
      this.SET_AFFAIR_SUBSCRIBED({ affairId, subscribed: false })
    },
    onQueryReset () {
      this.onQueryChange()
    },
    onQueryChange (value) {
      this.updateWidgetSettings({ widget: this.widget, q: value })
      this.getLatestAffairs({ widget: this.widget, q: value })
    },
  },
}
</script>
