import { api } from '@/utils/vuex'
import http from "@/plugins/axios";
import { firstDayOfWeek, lastDayOfWeek } from "@/utils/dateHelper";

export default {
  async getAvailableWidgets ({ state, commit }) {
    // Only load available widgets once
    if (state.availableWidgetsLoaded) {
      return
    }
    commit('SET_AVAILABLE_WIDGETS_LOADING', true)
    try {
      const res = await http.get(api('/widgets'))
      commit('SET_AVAILABLE_WIDGETS', res.data)
      commit('SET_AVAILABLE_WIDGETS_LOADED', true)
    } catch (err) {
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    } finally {
      commit('SET_AVAILABLE_WIDGETS_LOADING', false)
    }
  },
  async getActiveWidgets ({ state, commit }) {
    if (state.activeWidgetsLoaded) {
      return
    }
    commit('SET_ACTIVE_WIDGETS_LOADING', true)
    try {
      const res = await http.get(api('/widgets/active'))
      commit('SET_ACTIVE_WIDGETS', res.data)
      commit('SET_ACTIVE_WIDGETS_LOADED', true)
    } catch (err) {
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    } finally {
      commit('SET_ACTIVE_WIDGETS_LOADING', false)
    }
  },
  async toggleWidget ({ state, commit }, { widgetId }) {
    commit('SET_AVAILABLE_WIDGET_LOADING', { widgetId, loading: true })
    const itemExists = !!state.activeWidgets.find(widget => widget.id === widgetId)
    try {
      if (itemExists) {
        const res = await http.delete(api(`/widgets/active/${widgetId}`))
        commit('SET_ACTIVE_WIDGETS', res.data)
      } else {
        const res = await http.post(api(`/widgets/active/${widgetId}`))
        commit('SET_ACTIVE_WIDGETS', res.data)
      }
    } catch (err) {
      commit('app/setSnack', { message: `Error.widget${itemExists ? 'Remove' : 'Add'}`, color: 'error', translate: true }, { root: true })
    } finally {
      commit('SET_AVAILABLE_WIDGET_LOADING', { widgetId, loading: false })
    }
  },
  async getLatestAffairs ({ commit }, { widget, q }) {
    commit('SET_WIDGET_LOADING', { widgetId: widget.id, loading: true })
    try {
      const { item_count, subscribed, affair_types } = widget
      const affairTypeIds = affair_types.map((affairType) => affairType.id);
      const res = await http.get(
        api('/affairs/latest'),
        {
          params: {
            q,
            count: item_count,
            subscribed: subscribed ? true : undefined,
            type: affairTypeIds && affairTypeIds.length ? affairTypeIds.join(',') : undefined,
          },
        })
      commit('SET_WIDGET_DATA', { widgetId: widget.id, data: res.data })
    } catch (err) {
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    } finally {
      commit('SET_WIDGET_LOADING', { widgetId: widget.id, loading: false })
    }
  },
  async getLatestComments ({ commit }, widget) {
   commit('SET_WIDGET_LOADING', { widgetId: widget.id, loading: true })
    try {
      const { item_count, subscribed } = widget
      const res = await http.get(
        api('/comments/latest'),
        { params: { count: item_count, subscribed: subscribed ? true : undefined } })
      commit('SET_WIDGET_DATA', { widgetId: widget.id, data: res.data })
    } catch (err) {
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    } finally {
      commit('SET_WIDGET_LOADING', { widgetId: widget.id, loading: false })
    }
  },
  async getCalendarEvents ({ commit }, { start = firstDayOfWeek(), end = lastDayOfWeek(), widget } = {}) {
    commit('SET_WIDGET_LOADING', { widgetId: widget.id, loading: true })
    try {
      const res = await http.get(api('/events'),
        { params: { start, end, subscribed: true } })
      commit('SET_WIDGET_DATA', { widgetId: widget.id, data: res.data })
    } catch (err) {
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    } finally {
      commit('SET_WIDGET_LOADING', { widgetId: widget.id, loading: false })
    }
  },
  async getNextEvents ({ commit }, widget) {
    commit('SET_WIDGET_LOADING', { widgetId: widget.id, loading: true })
    try {
      const { item_count, subscribed, event_types } = widget
      const eventTypeIds = event_types.map((eventType) => eventType.id);
      const res = await http.get(
        api('/events/next'),
        {
          params: {
            count: item_count,
            subscribed: subscribed ? true : undefined,
            event_type: eventTypeIds && eventTypeIds.length ? eventTypeIds.join(',') : undefined,
          },
        })
      commit('SET_WIDGET_DATA', { widgetId: widget.id, data: res.data })
    } catch (err) {
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    } finally {
      commit('SET_WIDGET_LOADING', { widgetId: widget.id, loading: false })
    }
  },
  async updateWidgetSettings ({ commit }, { widget, q }) {
    try {
      const res = await http.patch(api(`/widgets/active/${widget.id}/settings`), { settings: { ...widget.pivot.settings, q } })
      commit('SET_ACTIVE_WIDGETS', res.data)
    } catch (err) {
      commit('app/setSnack', { message: 'Error.updateWidgetSettings', color: 'error', translate: true }, { root: true })
    }
  },
}
