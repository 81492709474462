/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import VueAnalytics from 'vue-analytics'
import Router from 'vue-router'
import Meta from 'vue-meta'
import store from '@/store'

// Routes
import paths from './paths'

export const defaultRouteName = 'dashboard'

function route (r) {
  return {
    ...r,
    name: r.name || r.view,
    component: (resolve) => import(
      `@/views/${r.view}.vue` // eslint-disable-line comma-dangle
    ).then(resolve),
  }
}

Vue.use(Router)

// Create a new router
const router = new Router({
  mode: 'history',
  routes: paths.map(path => route(path)).concat([
    { path: '*', redirect: { name: '404' } },
  ]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  // do not check authorization on login screen or if user is already set
  if (to.name === 'login' || store.state.auth.user) {
    next()
  } else {
    await store.dispatch('auth/check')

    if (store.state.auth.user) {
      next()
    } else {
      next({ name: 'login' })
    }
  }
})

Vue.use(Meta)

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.GOOGLE_ANALYTICS) {
  Vue.use(VueAnalytics, {
    id: process.env.GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== 'development',
    },
  })
}

export default router
