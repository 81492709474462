<template>
  <v-form @submit.prevent="changeQuery">
    <v-text-field
      v-model="localQuery"
      clearable
      single-line
      dense
      hide-details
      :label="$t('Dashboard.latestAffairsSearch.placeholder')"
      :disabled="loading"
      prepend-icon="$vuetify.icons.search"
      autocapitalize="none"
      @click:clear="clear"
    />
  </v-form>
</template>

<script>
export default {
  data () {
    return {
      // Use local copy of query to not always update when user state changes
      localQuery: this.query,
    }
  },
  props: {
    loading: {
      type: Boolean,
    },
    query: {
      type: String,
    },
  },
  methods: {
    clear () {
      this.$emit('reset')
    },
    changeQuery () {
      this.$emit('update', this.localQuery)
    },
  },
}
</script>
