export const COLOR_DARK = 'black';
export const COLOR_LIGHT = 'white';
export const DEFAULT_TEXT_COLOR = COLOR_LIGHT;

export const getContrastColor = (hex) => {
  /*
  From this W3C document: http://www.webmasterworld.com/r.cgi?f=88&d=9769&url=http://www.w3.org/TR/AERT#color-contrast
  Color brightness is determined by the following formula:
  ((Red value X 299) + (Green value X 587) + (Blue value X 114)) / 1000
  */
  const cutHex = (h) => (h.charAt(0) === '#') ? h.substring(1,7) : h;
  const hexToR = (h) => parseInt((cutHex(h)).substring(0, 2), 16);
  const hexToG = (h) => parseInt((cutHex(h)).substring(2, 4), 16);
  const hexToB = (h) => parseInt((cutHex(h)).substring(4, 6), 16);

  const hRed = hexToR(hex);
  const hGreen = hexToG(hex);
  const hBlue = hexToB(hex);

  const threshold = 130; /* about half of 256. Lower threshold equals more dark text on dark background */
  const cBrightness = ((hRed * 299) + (hGreen * 587) + (hBlue * 114)) / 1000;
  return cBrightness > threshold ? COLOR_DARK : COLOR_LIGHT;
}
