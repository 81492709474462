<template>
  <widgets-base
    :title="widget.title"
    :action="{ label: widget.action_label, to: { name: 'events-future', query: queryParams } }"
    :loading="loading"
  >
    <v-list
      class="next-events-list pa-0"
      two-line
    >
      <template v-if="loading">
        <v-list-item
          v-for="count in widget.item_count || 10"
          :key="count"
          class="pa-0"
        >
          <v-list-item-content class="pa-0">
            <v-skeleton-loader
              type="list-item-two-line"
            />
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item
        v-else-if="events.length > 0"
        v-for="event in events"
        :key="event.id"
        :to="!event.affair.is_blanco ? { name: 'affair-detail', params: { id: event.affair.id }, hash: `#event-${event.id}` } : null"
      >
        <v-list-item-content>
          <div class="d-flex align-center overflow-hidden" :class="{ 'mb-2': !event.affair.is_blanco }">
            <div class="font-weight-bold mr-2">{{ $options.filters.getFormattedEventdate(event.eventdate, event.enddate) }}</div>
            <div class="overline text-truncate" v-if="event.event_type">{{ event.event_type.name }}</div>
          </div>
          <div class="text-truncate" v-if="!event.affair.is_blanco">
            <v-chip
              v-if="event.affair.affair_number"
              color="primary"
              class="mr-2"
              small
            >
              {{ event.affair.affair_number }}
            </v-chip>
            <span>{{ event.affair.title }}</span>
          </div>
        </v-list-item-content>
        <v-list-item-action class="flex-shrink-0">
          <core-subscribe-button
            v-if="!event.affair.is_blanco"
            :affair="event.affair"
            @subscribed="onSubscribed(event.affair.id)"
            @unsubscribed="onUnsubscribed(event.affair.id)"
          />
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-content>
          {{ $t('Dashboard.widgets.nextEvents.empty') }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </widgets-base>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';

export default {
  name: 'NextEventsWidget',
  async mounted () {
    await this.getNextEvents(this.widget)
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    queryParams () {
      const queryParams = {}
      if (this.widget.event_types && this.widget.event_types.length > 0) {
        queryParams.event_type = this.widget.event_types.map((eventType) => eventType.id).join(',')
      }
      if (this.widget.subscribed) {
        queryParams.subscribed = true
      }
      return queryParams
    },
  },
  methods: {
    ...mapActions('widgets', [
      'getNextEvents',
    ]),
    ...mapMutations('widgets', [
      'SET_AFFAIR_SUBSCRIBED',
    ]),
    onSubscribed (affairId) {
      this.SET_AFFAIR_SUBSCRIBED({ affairId, subscribed: true })
    },
    onUnsubscribed (affairId) {
      this.SET_AFFAIR_SUBSCRIBED({ affairId, subscribed: false })
    },
  },
}
</script>
