<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="optionsPassthrough"
    :server-items-length="serverItemsLength"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: itemsPerPageOptions,
    }"
    :expanded.sync="expanded"
    :mobile-breakpoint="0"
    item-key="id"
    class="affair-list"
    show-expand
    @click:row="onRowClick"
    :item-class="itemRowBackground"
  >
    <template v-slot:loading>
      {{ $t('Affairs.loading') }}
    </template>
    <template v-slot:no-data>
      {{ $t('Affairs.noData') }}
    </template>
    <template v-slot:item.submission_date="{ item }">
      {{ item.submission_date ? $options.filters.formatDate(item.submission_date) : '-' }}
    </template>
    <template v-slot:item.affair_number="{ item }">
      {{ item.affair_number ? item.affair_number : '-' }}
    </template>
    <template v-slot:item.affair_type_name="{ item }">
      {{ item.affair_type ? item.affair_type.name : '-' }}
    </template>
    <template v-slot:item.actions="{ item }">
      <div
        class="actions"
        @click.stop
      >
        <core-comment-section-button
          v-if="hasPermission(permissions.COMMENTS)"
          :affair="item"
        />
        <core-subscribe-button
          v-if="hasPermission(permissions.AFFAIRS_SUBSCRIBERS)"
          :affair="item"
          @subscribed="onSubscribed(item.id)"
          @unsubscribed="onUnsubscribed(item.id)"
        />
        <core-assign-button
          v-if="hasPermission(permissions.SUBSCRIBERS)"
          :affair="item"
          module="Subscribers"
          icon="$vuetify.icons.users"
          :label-accessor="item => `${item.name} ${item.surname}`"
        />
        <core-assign-button
          v-if="hasPermission(permissions.GLOBAL_ISSUES)"
          :affair="item"
          :create-item-route="{ name: 'issues' }"
          module="GlobalIssues"
          icon="$vuetify.icons.userTag"
        />
        <core-assign-button
          v-if="hasPermission(permissions.AFFAIRS_ISSUES)"
          :affair="item"
          :create-item-route="{ name: 'issues' }"
          module="Issues"
          icon="$vuetify.icons.tag"
        />
      </div>
    </template>
    <!-- eslint-disable-next-line vue/no-template-shadow -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <core-affair-detail-info :label="$t('Affairs.politicalLevel')">
          {{ item.political_level ? item.political_level.name : '-' }}
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Affairs.author')">
          {{ item.author ? item.author.name : '-' }}
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Affairs.topics')">
          <div v-if="item.topics && item.topics.length > 0">
            <v-chip
              v-for="topic in item.topics"
              :key="topic.id"
              class="ma-1"
            >
              {{ topic.name }}
            </v-chip>
          </div>
          <template v-else>-</template>
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Affairs.globalIssues')">
          <div v-if="item.global_issues && item.global_issues.length > 0">
            <v-chip
              v-for="globalIssue in item.global_issues"
              :key="globalIssue.id"
              class="ma-1"
            >
              {{ globalIssue.name }}
            </v-chip>
          </div>
          <template v-else>-</template>
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Affairs.issues')">
          <div v-if="item.issues && item.issues.length > 0">
            <v-chip
              v-for="issue in item.issues"
              :key="issue.id"
              class="ma-1"
            >
              {{ issue.name }}
            </v-chip>
          </div>
          <template v-else>-</template>
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Affairs.state')">
          {{ item.state ? item.state.name : '-' }}
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Affairs.nextState')">
          {{ item.next_state ? item.next_state.name : '-' }}
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Affairs.description')">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div
            class="text-line-clamp text-line-clamp-5"
            :title="item.description"
            v-html="item.description ? $options.filters.nl2br(item.description) : '-'"
          />
        </core-affair-detail-info>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapMutations } from 'vuex'

const EDIT_STATUS_FINISHED = 4

export default {
  name: 'AffairList',
  props: {
    items: {
      type: Array,
      default () {
        return []
      },
    },
    options: {
      type: Object,
      default () {
        return {}
      },
    },
    loading: {
      type: Boolean,
      default: true,
    },
    serverItemsLength: {
      type: Number,
      default: 0,
    },
    itemsPerPageOptions: {
      type: Array,
      default () {
        return []
      },
    },
  },
  data () {
    return {
      headers: [
        {
          sortable: true,
          text: this.$t('Affairs.submissionDate'),
          value: 'submission_date',
          class: 'submission-date',
        },
        {
          sortable: true,
          text: this.$t('Affairs.number'),
          value: 'affair_number',
          class: 'affair-number',
        },
        {
          sortable: true,
          text: this.$t('Affairs.title'),
          value: 'title',
          class: 'affair-title',
        },
        {
          sortable: true,
          text: this.$t('Affairs.affairType'),
          value: 'affair_type_name',
          class: 'affair-type',
        },
        {
          sortable: false,
          text: '',
          value: 'actions',
          class: 'actions',
        },
      ],
      expanded: [],
    }
  },
  computed: {
    optionsPassthrough: {
      get () {
        return this.options
      },
      set (value) {
        this.$emit('update:options', value)
      },
    },
  },
  methods: {
    ...mapMutations('affairs', [
      'SET_AFFAIRS_SUBSCRIBED',
    ]),
    onRowClick (affair) {
      this.$router.push({ name: 'affair-detail', params: { id: affair.id } })
    },
    onSubscribed (affairId) {
      this.SET_AFFAIRS_SUBSCRIBED({ affairId, subscribed: true })
    },
    onUnsubscribed (affairId) {
      this.SET_AFFAIRS_SUBSCRIBED({ affairId, subscribed: false })
    },
    itemRowBackground ({ edit_status_id }) {
      return edit_status_id === EDIT_STATUS_FINISHED ? 'status-finished' : ''
    },
  },
}
</script>

<style>
.status-finished {
  background-color: #3b439522;  
}
</style>