import http from '@/plugins/axios'
import { api } from '@/utils/vuex'
import { permissions } from '@/utils/permissions'

const loadRelations = ({ dispatch, getters }) => {
  // todo: check AFFAR_ISSUES too!!!
  if (getters.hasPermission(permissions.ISSUES)) {
    dispatch('issues/getItems', null, { root: true })
  }
  if (getters.hasPermission(permissions.GLOBAL_ISSUES)) {
    dispatch('global-issues/getItems', null, { root: true })
  }
  if (getters.hasPermission(permissions.SUBSCRIBERS)) {
    dispatch('subscribers/getItems', null, { root: true })
  }
  if (getters.hasPermission(permissions.COMMENTS)) {
    dispatch('affairs/getNoteTypes', null, { root: true })
  }
  dispatch('getOtherUsers')
}

// Set filter values and defaults for all modules
const setFilterValuesAndDefaults = ({ commit, state }) => {
  commit('affairs/SET_FILTER_VALUES_AND_DEFAULTS', {
    defaults: state.user.default_settings,
    settings: state.user.settings,
  }, { root: true })
  commit('calendar/SET_FILTER_VALUES_AND_DEFAULTS', {
    defaults: state.user.default_settings,
    settings: state.user.settings,
  }, { root: true })
  commit('events-future/SET_FILTER_VALUES_AND_DEFAULTS', {
    defaults: state.user.default_settings,
    settings: state.user.settings,
    moduleName: 'events-future',
  }, { root: true })
  commit('events-past/SET_FILTER_VALUES_AND_DEFAULTS', {
    defaults: state.user.default_settings,
    settings: state.user.settings,
  }, { root: true })
}

export default {
  async check ({ commit, state, dispatch, getters }) {
    try {
      commit('setLoading', true)
      const res = await http.get(api('/auth'))
      commit('setUser', res.data)
      setFilterValuesAndDefaults({ commit, state })
      loadRelations({ dispatch, getters })
    } catch (e) {
      commit('setUser', null)
    } finally {
      commit('setLoading', false)
    }
  },
  async logout ({ commit }) {
    await http.get(api('/auth/logout'))
    commit('setUser', null)
  },
  async login ({ commit, state, dispatch, getters }, data) {
    try {
      commit('setLoading', true)
      const res = await http.post(api('/auth/login'), data)
      commit('setUser', res.data)
      setFilterValuesAndDefaults({ commit, state })
      loadRelations({ dispatch, getters })
    } catch (e) {
      if (e.response && e.response.status === 403) {
        commit('app/setSnack', { message: e.response.data, color: 'error', translate: true }, { root: true })
      } else {
        commit('app/setSnack', { message: 'Error.login', color: 'error', translate: true }, { root: true })
      }
      commit('setUser', null)
    } finally {
      commit('setLoading', false)
    }
  },
  async updateUser ({ commit, state }, { intervalId, settings, showSuccessSnack = true }) {
    const payload = {
      ...(intervalId !== undefined && { interval_id: intervalId }),
      ...(settings !== undefined && { settings: {
        ...state.user.settings,
        ...settings,
      } }),
    }
    try {
      const res = await http.patch(api('/account'), payload)
      commit('setUser', res.data)
      if (showSuccessSnack) {
        commit('app/setSnack', { message: 'User.settingsSaved', color: 'success', translate: true }, { root: true })
      }
    } catch (err) {
      commit('app/setSnack', { message: 'Error.updateUser', color: 'error', translate: true }, { root: true })
    }
  },
  async resetSettings ({ commit, state }, { module }) {
    const settingsWithoutModule =
      Object.keys(state.user.settings).filter((settingKey) => !settingKey.startsWith(`${module}-`))
            .reduce((settingsWithoutModule, key) => {
              settingsWithoutModule[key] = state.user.settings[key];
              return settingsWithoutModule;
            }, {});
    try {
      const res = await http.patch(api('/account'), { settings: settingsWithoutModule })
      commit('setUser', res.data)
      commit(`${module}/SET_FILTER_VALUES_AND_DEFAULTS`, {
        defaults: state.user.default_settings,
        settings: state.user.settings,
      }, { root: true })
    } catch (err) {
      commit('app/setSnack', { message: 'Error.updateUser', color: 'error', translate: true }, { root: true })
    }
  },
  async getOtherUsers({ commit }){
    try {
      const res = await http.get(api('/customer/other-users'))
      commit('SET_OTHER_USERS', res.data)
    } catch (error) {
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    }
  },
}
