<template>
  <component :is="layout" />
</template>

<script>
export default {
  computed: {
    layout () {
      // set fullscreen as default, otherwise the drawer is loaded and flickers on login page.
      return (this.$route.meta.layout || 'fullscreen') + '-layout'
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/index.scss';
</style>
