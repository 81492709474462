<template>
  <v-expansion-panels v-model="expanded">
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="font-weight-bold">
          {{ $t('Core.Filters.expansionPanelLabel') }}
          <span
            v-if="activeFiltersCount > 0"
            class="active-filters-badge primary ml-1"
          >
            {{ activeFiltersCount }}
          </span>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row dense>
          <v-col
            v-for="filter in filters"
            :key="filter.name"
            cols="12"
            md="6"
          >
            <core-treeselect-filter
              v-if="filter.type === 'treeselect'"
              :filter="filter"
              @input="setValue($event, filter)"
            />
            <v-select
              v-else-if="filter.type === 'multiselect'"
              :value="getValue(filter)"
              :items="normalizedItems(filter)"
              :label="$t(filter.label)"
              :chips="true"
              :deletable-chips="true"
              :small-chips="true"
              :multiple="true"
              @change="setValue($event, filter)"
            />
            <v-select
              v-else
              :value="getValue(filter)"
              :items="normalizedItems(filter)"
              :label="$t(filter.label)"
              :clearable="true"
              @change="setValue($event, filter)"
            />
          </v-col>
        </v-row>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'Filters',
  props: {
    module: {
      type: String,
      required: true,
    },
    section: {
      type: String,
      required: true,
    },
    activeFiltersCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    filters () {
      return this.$store.state[this.module][this.section]
    },
    expanded: {
      get () {
        return this.$store.state[this.module].filtersExpanded
      },
      set (expanded) {
        this.$store.commit(`${this.module}/SET_FILTER_EXPANDED`, expanded)
      },
    },
  },
  methods: {
    getValue (filter) {
      return filter.value
    },
    setValue (value, filter) {
      this.$store.commit(`${this.module}/SET_FILTER_VALUE`, { filterName: filter.name, value })
      if (filter.persist) {
        this.$store.dispatch('auth/updateUser', {
          settings: { [filter.persist]: value },
          showSuccessSnack: false,
        }, { root: true })
      }
      this.$emit('update')
    },
    normalizedItems (filter) {
      const itemText = filter.itemText || 'text'
      const itemValue = filter.itemValue || 'value'
      return filter.items.map(item => {
        return {
          value: item[itemValue],
          text: item.translate ? this.$t(item[itemText]) : item[itemText], // Translate item labels if needed
        }
      })
    },
  },
}
</script>
