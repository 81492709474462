import http from '@/plugins/axios'
import { api, filterActions, perpareFilterParams } from '@/utils/vuex'

export default {
  async getEvents ({ commit, state }) {
    const { page, itemsPerPage, sortDesc, sortBy } = state.options
    const filterParams = perpareFilterParams(state.filters)
    commit('SET_EVENTS_LOADING', true)
    try {
      const res = await http.get(
        api('/events/past'),
        { params: { page, rowsPerPage: itemsPerPage, sortBy, descending: sortDesc, ...filterParams } })
      commit('SET_EVENTS', res.data)
      commit('SET_EVENTS_SERVER_ITEMS_LENGTH', res.data.total)
    } catch (err) {
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    } finally {
      commit('SET_EVENTS_LOADING', false)
    }
  },
  async getFilterItems ({ commit, state }) {
    // Only load filter items once
    if (state.filterItemsFetched) {
      return
    }

    try {
      const res = await http.get(api('/events/filters'))
      commit('SET_FILTER_ITEMS', res.data.filters)
      commit('SET_FILTER_ITEMS_FETCHED', true)
    } catch (err) {
      commit('SET_FILTER_ITEMS_FETCHED', false)
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    }
  },
  ...filterActions,
}
