<template>
  <v-app-bar
    id="core-toolbar"
    app
    flat
    class="d-print-none"
  >
    <v-app-bar-nav-icon
      @click="onHamburgerClick"
    />
    <v-spacer />
    <v-toolbar-items>
      <v-btn
        text
        tile
        @click="onPrintClick"
      >
        <v-icon
          small
          class="mr-md-2"
        >
          $vuetify.icons.print
        </v-icon>
        <span class="d-none d-md-inline">{{ $t('Core.Toolbar.print') }}</span>
      </v-btn>
      <v-menu
        bottom
        left
        content-class="profile"
        offset-y
        transition="slide-y-transition"
      >
        <template v-slot:activator="{ on }">
          <v-btn
            text
            tile
            v-on="on"
          >
            <v-icon
              left
              small
            >
              $vuetify.icons.user
            </v-icon>
            {{ fullName }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="onProfileClick">
            <v-list-item-icon>
              <v-icon small>$vuetify.icons.addressCard</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('Core.Toolbar.profile') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="onLogout">
            <v-list-item-icon>
              <v-icon small>$vuetify.icons.signOutAlt</v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ $t('Core.Toolbar.logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import {
  mapMutations,
  mapActions,
} from 'vuex'

export default {
  computed: {
    fullName () {
      const user = this.$store.state.auth.user
      return user ? `${user.name} ${user.surname}` : ''
    },
  },
  methods: {
    ...mapMutations('app', ['setDrawer']),
    ...mapActions('auth', ['logout']),
    onHamburgerClick () {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    async onLogout () {
      await this.logout()
      // force browser reload on logout to reset local state
      window.location = '/login'
    },
    onProfileClick () {
      this.$router.push({ name: 'user' })
    },
    onPrintClick () {
      window.print()
    },
  },
}
</script>
