<template>
  <v-btn
    :title="$t('Core.CommentSectionButton.label')"
    small
    text
    rounded
    :color="textColor"
    class="ma-1 comments-button"
    :class="buttonClass"
    @click.stop="toggleComments"
  >
    <span class="mr-1">{{ commentCount }}</span>
    <v-icon
      small
      :color="textColor"
    >
      $vuetify.icons.comments
    </v-icon>
  </v-btn>
</template>

<script>
import { COMMENT_SECTION_HASH, isCommentSectionOpen } from '@/utils/comments';

export default {
  name: 'CommentSectionButton',
  props: {
    affair: {
      type: Object,
      required: true,
    },
  },
  computed: {
    commentSectionOpen () {
      return isCommentSectionOpen(this.$route)
    },
    commentCount () {
      return (this.affair.comments || []).length
    },
    buttonClass () {
      if (this.commentSectionOpen) {
        if (this.commentCount > 0) {
          return 'primary'
        } else {
          return 'tertiary'
        }
      }
      return undefined
    },
    textColor () {
      if (this.commentSectionOpen) {
        return 'white'
      } else if (this.commentCount > 0) {
        return 'primary'
      }
      return undefined
    },
  },
  methods: {
    toggleComments () {
      if (!this.commentSectionOpen) {
        this.$router.push({ name: 'affair-detail', params: { id: this.affair.id }, hash: COMMENT_SECTION_HASH })
      } else {
        this.$router.push({ name: 'affair-detail', params: { id: this.affair.id } })
      }
    },
  },
}
</script>
