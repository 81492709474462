<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :options.sync="optionsPassthrough"
    :server-items-length="serverItemsLength"
    :loading="loading"
    :footer-props="{
      itemsPerPageOptions: itemsPerPageOptions,
    }"
    :expanded.sync="expanded"
    :mobile-breakpoint="0"
    :item-class="getItemClass"
    item-key="id"
    class="event-list"
    show-expand
    @click:row="onRowClick"
  >
    <template v-slot:loading>
      {{ loadingText }}
    </template>
    <template v-slot:no-data>
      {{ noDataText }}
    </template>
    <template v-slot:item.data-table-expand="{ expand, item, isExpanded }">
      <v-icon
        v-if="!item.affair.is_blanco"
        class="v-data-table__expand-icon"
        :class="{ 'v-data-table__expand-icon--active': isExpanded }"
        @click.stop="() => expand(!isExpanded)"
      >
        $vuetify.icons.expand
      </v-icon>
    </template>
    <template v-slot:item.eventdate="{ item }">
      {{ $options.filters.getFormattedEventdate(item.eventdate, item.enddate) }}
    </template>
    <template v-slot:item.event_type_name="{ item }">
      {{ item.event_type ? item.event_type.name : '-' }}
    </template>
    <template v-slot:item.affair_number="{ item }">
      {{ !item.affair.is_blanco && item.affair.affair_number ? item.affair.affair_number : '-' }}
    </template>
    <template v-slot:item.affair_title="{ item }">
      {{ !item.affair.is_blanco && item.affair.title ? item.affair.title : '-' }}
    </template>
    <template v-slot:item.actions="{ item }">
      <div class="actions">
        <core-subscribe-button
          v-if="!item.affair.is_blanco"
          :affair="item.affair"
          @subscribed="onSubscribed(item.affair.id)"
          @unsubscribed="onUnsubscribed(item.affair.id)"
        />
      </div>
    </template>
    <!-- eslint-disable-next-line vue/no-template-shadow -->
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <core-affair-detail-info
          v-if="item.author"
          :label="$t('Core.EventList.author')"
        >
          {{ item.author.name }}
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Core.EventList.description')">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="item.description ? $options.filters.nl2br(item.description) : '-'" />
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Core.EventList.affairTopics')">
          <div v-if="item.affair.topics && item.affair.topics.length > 0">
            <v-chip
              v-for="topic in item.affair.topics"
              :key="topic.id"
              class="ma-1"
            >
              {{ topic.name }}
            </v-chip>
          </div>
          <template v-else>-</template>
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Core.EventList.affairGlobalIssues')">
          <div v-if="item.affair.global_issues && item.affair.global_issues.length > 0">
            <v-chip
              v-for="globalIssue in item.affair.global_issues"
              :key="globalIssue.id"
              class="ma-1"
            >
              {{ globalIssue.name }}
            </v-chip>
          </div>
          <template v-else>-</template>
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Core.EventList.affairIssues')">
          <div v-if="item.affair.issues && item.affair.issues.length > 0">
            <v-chip
              v-for="issue in item.affair.issues"
              :key="issue.id"
              class="ma-1"
            >
              {{ issue.name }}
            </v-chip>
          </div>
          <template v-else>-</template>
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Core.EventList.affairType')">
          {{ item.affair.affair_type ? item.affair.affair_type.name : '-' }}
        </core-affair-detail-info>
        <core-affair-detail-info :label="$t('Core.EventList.affairState')">
          {{ item.affair.state ? item.affair.state.name : '-' }}
        </core-affair-detail-info>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'EventList',
  props: {
    items: {
      type: Array,
      default () {
        return []
      },
    },
    options: {
      type: Object,
      default () {
        return {}
      },
    },
    loading: {
      type: Boolean,
      default: true,
    },
    serverItemsLength: {
      type: Number,
      default: 0,
    },
    itemsPerPageOptions: {
      type: Array,
      default () {
        return []
      },
    },
    module: {
      type: String,
      default: '',
    },
    loadingText: {
      type: String,
      default: '',
    },
    noDataText: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      headers: [
        {
          sortable: true,
          text: this.$t('Core.EventList.eventdate'),
          value: 'eventdate',
          class: 'eventdate',
        },
        {
          sortable: true,
          text: this.$t('Core.EventList.eventType'),
          value: 'event_type_name',
          class: 'event-type',
        },
        {
          sortable: true,
          text: this.$t('Core.EventList.affairNumber'),
          value: 'affair_number',
          class: 'affair-number',
        },
        {
          sortable: true,
          text: this.$t('Core.EventList.affairTitle'),
          value: 'affair_title',
          class: 'affair-title',
        },
        {
          sortable: false,
          text: '',
          value: 'actions',
          class: 'actions',
        },
      ],
      expanded: [],
    }
  },
  computed: {
    optionsPassthrough: {
      get () {
        return this.options
      },
      set (value) {
        this.$emit('update:options', value)
      },
    },
  },
  methods: {
    onRowClick (event) {
      if (!event.affair.is_blanco) {
        this.$router.push({ name: 'affair-detail', params: { id: event.affair.id }, hash: `#event-${event.id}` })
      }
    },
    onSubscribed (affairId) {
      this.$store.commit(`${this.module}/SET_EVENTS_SUBSCRIBED`, { affairId, subscribed: true })
      this.$store.commit('affairs/SET_AFFAIRS_SUBSCRIBED', { affairId, subscribed: true })
    },
    onUnsubscribed (affairId) {
      this.$store.commit(`${this.module}/SET_EVENTS_SUBSCRIBED`, { affairId, subscribed: false })
      this.$store.commit('affairs/SET_AFFAIRS_SUBSCRIBED', { affairId, subscribed: false })
    },
    getItemClass (event) {
      return event.affair.is_blanco ? 'is-blanco' : '';
    },
  },
}
</script>
