export default {
  themes: {
    light: {
      primary: '#3b4395',
      secondary: '#2a2f6b',
      tertiary: '#495057',
      accent: '#3b4395',
      error: '#f54f1f',
      info: '#17a2b8',
      success: '#28a745',
      warning: '#ffc107',
    },
  },
}
