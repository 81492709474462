import Vue from 'vue'
import { permissions } from '@/utils/permissions'

const permission = {
  data () {
    return {
      permissions,
    }
  },
  methods: {
    hasPermission (permission) {
      return this.$store.getters['auth/hasPermission'](permission)
    },
  },
}

Vue.mixin(permission)
