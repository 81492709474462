export default {
  availableWidgets: [],
  availableWidgetsLoading: true,
  availableWidgetsLoaded: false,
  activeWidgets: [],
  activeWidgetsLoading: true,
  activeWidgetsLoaded: false,
  widgetsLoading: [], // array of widgetIds which are currently loading
  widgetsData: {},
}
