import { filter } from '@/utils/vuex';

export default {
  SET_EVENTS (state, events) {
    state.events = events
  },
  SET_LOADING (state, loading) {
    state.loading = loading
  },
  ...filter,
}
