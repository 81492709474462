import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import theme from './theme'
import { icons } from './fontawesome'
import de from 'vuetify/lib/locale/de' // Load translation for vuetify labels

Vue.use(Vuetify)

const vuetifyOptions = {
  icons,
  theme,
  lang: {
    locales: { de },
    current: 'de',
  },
}

export default new Vuetify(vuetifyOptions)
