<template>
  <div>
    <h2>{{ t('issuesTitle') }}</h2>
    <v-card class="mb-6">
      <v-card-text>
        <div
          v-if="issuesLoading"
          class="mb-3"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            class="mr-3"
          />
          {{ t('loading') }}
        </div>
        <template v-else-if="!issuesLoading && issues.length > 0">
          <v-list>
            <v-list-item
              v-for="issue in issues"
              :key="issue.id"
            >
              <v-list-item-avatar>
                <v-icon small>{{ icon }}</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ issue.name }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                  icon
                  class="mr-2"
                  @click.stop="onIssueEditClick(issue)"
                >
                  <v-icon
                    :title="t('editAction', { name: issue.name })"
                    small
                  >
                    $vuetify.icons.edit
                  </v-icon>
                </v-btn>
                <v-btn
                  icon
                  @click="onIssueDeleteClick(issue)"
                >
                  <v-icon
                    :title="t('deleteAction', { name: issue.name })"
                    small
                    color="error"
                  >
                    $vuetify.icons.trash
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </template>
        <v-btn
          tile
          @click="onIssueAddClick"
        >
          <v-icon
            left
            small
          >
            $vuetify.icons.plus
          </v-icon>
          {{ t('add') }}
        </v-btn>
      </v-card-text>
    </v-card>
    <core-issue-dialog
      v-if="issueEditDialog.issue"
      :issue="issueEditDialog.issue"
      :open="issueEditDialog.open"
      :title="t('edit')"
      :ok-text="t('editSave')"
      :cancel-text="t('editCancel')"
      :name-required-text="t('nameRequired')"
      :name-label="t('name')"
      :loading="issueEditDialog.loading"
      @submit="onIssueEditFormSubmit"
      @cancel="onIssueEditCancelClick"
    />
    <core-issue-dialog
      v-if="issueAddDialog.issue"
      :issue="issueAddDialog.issue"
      :open="issueAddDialog.open"
      :title="t('add')"
      :ok-text="t('addSave')"
      :cancel-text="t('addCancel')"
      :name-required-text="t('nameRequired')"
      :name-label="t('name')"
      :loading="issueAddDialog.loading"
      @submit="onIssueAddFormSubmit"
      @cancel="onIssueAddCancelClick"
    />
    <v-dialog
      v-model="issueDeleteDialog.open"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">
          {{ t('delete') }}
        </v-card-title>
        <v-card-text>
          {{ t('deleteText', { name: issueDeleteDialog.issue.name }) }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            tile
            :loading="issueDeleteDialog.loading"
            :disabled="issueDeleteDialog.loading"
            @click="onIssueDeleteOkClick"
          >
            {{ t('deleteOk') }}
          </v-btn>
          <v-btn
            text
            tile
            :disabled="issueDeleteDialog.loading"
            @click="onIssueDeleteCancelClick"
          >
            {{ t('deleteCancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { camelToKebab } from '@/utils/vuex'

export default {
  name: 'EditableIssueList',
  props: {
    module: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
  },
  data () {
    return {
      issueEditDialog: {
        open: false,
        loading: false,
        issue: {},
        cachedIssue: {},
      },
      issueAddDialog: {
        open: false,
        loading: false,
        issue: {},
      },
      issueDeleteDialog: {
        open: false,
        loading: false,
        issue: {},
      },
    }
  },
  computed: {
    store () {
      return camelToKebab(this.module)
    },
    issues () {
      return this.$store.state[this.store].items
    },
    issuesLoading () {
      return this.$store.state[this.store].itemsLoading
    },
  },
  methods: {
    async addIssue (issue) {
      await this.$store.dispatch(`${this.store}/addItem`, issue)
    },
    async updateIssue (issue) {
      await this.$store.dispatch(`${this.store}/updateItem`, issue)
    },
    async deleteIssue (issue) {
      await this.$store.dispatch(`${this.store}/deleteItem`, issue)
    },
    t (key, args) {
      return this.$t(`${this.module}.${key}`, args)
    },
    onIssueAddClick () {
      this.openIssueAddDialog()
    },
    onIssueEditClick (issue) {
      this.openIssueEditDialog(issue)
    },
    onIssueDeleteClick (issue) {
      this.openIssueDeleteDialog(issue)
    },
    async onIssueEditFormSubmit () {
      // Only save issue if name has changed
      if (this.issueEditDialog.issue.name !== this.issueEditDialog.cachedIssue.name) {
        this.issueEditDialog.loading = true
        await this.updateIssue(this.issueEditDialog.issue)
        this.issueEditDialog.loading = false
      }
      this.closeIssueEditDialog()
    },
    onIssueEditCancelClick () {
      this.closeIssueEditDialog()
    },
    openIssueEditDialog (issue) {
      // clone issue
      this.issueEditDialog.issue = Object.assign({}, issue)
      this.issueEditDialog.cachedIssue = Object.assign({}, issue)
      this.issueEditDialog.open = true
    },
    closeIssueEditDialog () {
      this.issueEditDialog.open = false
      this.issueEditDialog.issue = {}
      this.issueEditDialog.cachedIssue = {}
    },
    openIssueAddDialog () {
      // clone issue
      this.issueAddDialog.issue = { name: '' }
      this.issueAddDialog.open = true
    },
    async onIssueAddFormSubmit () {
      this.issueAddDialog.loading = true
      await this.addIssue(this.issueAddDialog.issue)
      this.issueAddDialog.loading = false
      this.closeIssueAddDialog()
    },
    onIssueAddCancelClick () {
      this.closeIssueAddDialog()
    },
    closeIssueAddDialog () {
      this.issueAddDialog.open = false
      this.issueAddDialog.issue = {}
    },
    openIssueDeleteDialog (issue) {
      // clone issue
      this.issueDeleteDialog.issue = Object.assign({}, issue)
      this.issueDeleteDialog.open = true
    },
    async onIssueDeleteOkClick () {
      this.issueDeleteDialog.loading = true
      await this.deleteIssue(this.issueDeleteDialog.issue)
      this.issueDeleteDialog.loading = false
      this.closeIssueDeleteDialog()
    },
    onIssueDeleteCancelClick () {
      this.closeIssueDeleteDialog()
    },
    closeIssueDeleteDialog () {
      this.issueDeleteDialog.open = false
      this.issueDeleteDialog.issue = {}
    },
  },
}
</script>
