<template>
  <div>
    <v-dialog
      v-model="open"
      max-width="600"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          :disabled="loading"
          small
        >
          <slot name="button-content" />
        </v-btn>
      </template>
      <template>
        <v-card class="pa-2">
          <v-text-field
            :disabled="loading || internalLoading"
            ref="value"
            :value="iCalUrl"
            :append-outer-icon="copied ? '$vuetify.icons.clipboardCheck' : '$vuetify.icons.clipboard'"
            :loading="internalLoading"
            solo
            hide-details
            readonly
            @click:append-outer="onCopyClick"
          />
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'CopyToClipboardButton',
  data () {
    return {
      open: false,
      copied: false,
      iCalUrl: undefined,
      internalLoading: true,
    }
  },
  props: {
    loading: {
      type: Boolean,
    },
  },
  methods: {
    ...mapActions('calendar',
      [
        'getICalUrl',
      ],
    ),
    onCopyClick () {
      const inputValue = this.$refs.value;
      inputValue.focus();
      document.execCommand('selectAll');
      const copySuccess = document.execCommand('copy');
      this.copied = copySuccess;
    },
  },
  watch: {
    async open(open) {
      if (open) {
        this.internalLoading = true;
        this.copied = false
        this.iCalUrl = "Getting URL from server.."
        this.iCalUrl = await this.getICalUrl(this.$store.state.auth.user.api_token) || "Loading the URL from the server failed."
        this.internalLoading = false;
      }
    },
  },
}
</script>

