import { filter } from '@/utils/vuex'

export default {
  SET_EVENTS (state, events) {
    state.events = events
  },
  SET_EVENTS_LOADING (state, loading) {
    state.loading = loading
  },
  SET_EVENTS_PAGE (state, page) {
    state.options.page = page
  },
  SET_EVENTS_SERVER_ITEMS_LENGTH (state, serverItemsLength) {
    state.serverItemsLength = serverItemsLength
  },
  SET_EVENTS_OPTIONS (state, options) {
    state.options = options
  },
  SET_EVENTS_SUBSCRIBED (state, { affairId, subscribed }) {
    // Find all events which have relation to affair
    const events = state.events.data.filter(event => event.affair.id === affairId)
    events.forEach(event => {
      event.affair.subscribed = subscribed
    })
  },
  ...filter,
}
