import Vue from 'vue'
import moment from 'moment'
import { getFormattedEventdate, READABLE_DATE_FORMAT } from '@/utils/dateHelper';

Vue.filter('formatDate', function (date, format = READABLE_DATE_FORMAT) {
  return date ? moment(date).format(format) : date
})

Vue.filter('getFormattedEventdate', function (start, end) {
  return getFormattedEventdate(start, end)
})
