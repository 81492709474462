import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons/faCircle'
import { faCircle as faCircleRegular } from '@fortawesome/free-regular-svg-icons/faCircle'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons/faCalendarAlt'
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons/faCalendarPlus'
import { faCalendarMinus } from '@fortawesome/free-solid-svg-icons/faCalendarMinus'
import { faCalendar } from '@fortawesome/free-solid-svg-icons/faCalendar'
import { faNewspaper } from '@fortawesome/free-solid-svg-icons/faNewspaper'
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars'
import { faUser } from '@fortawesome/free-solid-svg-icons/faUser'
import { faAddressCard } from '@fortawesome/free-solid-svg-icons/faAddressCard'
import { faSortUp } from '@fortawesome/free-solid-svg-icons/faSortUp'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt'
import { faKey } from '@fortawesome/free-solid-svg-icons/faKey'
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle'
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell'
import { faPaperclip } from '@fortawesome/free-solid-svg-icons/faPaperclip'
import { faPhone } from '@fortawesome/free-solid-svg-icons/faPhone'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons/faEnvelope'
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons/faCheckSquare'
import { faSquare as faSquareRegular } from '@fortawesome/free-regular-svg-icons/faSquare'
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch'
import { faComment } from '@fortawesome/free-solid-svg-icons/faComment'
import { faFileAlt } from '@fortawesome/free-solid-svg-icons/faFileAlt'
import { faPlus } from '@fortawesome/free-solid-svg-icons/faPlus'
import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash'
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit'
import { faLink } from '@fortawesome/free-solid-svg-icons/faLink'
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag'
import { faTags } from '@fortawesome/free-solid-svg-icons/faTags'
import { faUserTag } from '@fortawesome/free-solid-svg-icons/faUserTag'
import { faUsers } from '@fortawesome/free-solid-svg-icons/faUsers'
import { faPrint } from '@fortawesome/free-solid-svg-icons/faPrint'
import { faComments } from '@fortawesome/free-solid-svg-icons/faComments'
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons/faGlobeAmericas'
import { faClock as faClockRegular } from '@fortawesome/free-regular-svg-icons/faClock'
import { faTachometerAlt } from '@fortawesome/free-solid-svg-icons/faTachometerAlt'
import { faMinusSquare } from '@fortawesome/free-solid-svg-icons/faMinusSquare'
import { faClipboard } from '@fortawesome/free-regular-svg-icons/faClipboard'
import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons/faClipboardCheck'
import { faCog } from '@fortawesome/free-solid-svg-icons/faCog'
import { faItalic } from '@fortawesome/free-solid-svg-icons/faItalic'
import { faBold } from '@fortawesome/free-solid-svg-icons/faBold'
import { faUnderline } from '@fortawesome/free-solid-svg-icons/faUnderline'
import { faListUl } from '@fortawesome/free-solid-svg-icons/faListUl'
import { faListOl } from '@fortawesome/free-solid-svg-icons/faListOl'

Vue.component('font-awesome-icon', FontAwesomeIcon) // Register component globally

library.add(
  faCircle,
  faCircleRegular,
  faCalendarAlt,
  faCalendarPlus,
  faCalendarMinus,
  faCalendar,
  faNewspaper,
  faBars,
  faUser,
  faAddressCard,
  faSortUp,
  faCaretDown,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faSignOutAlt,
  faKey,
  faTimes,
  faTimesCircle,
  faBell,
  faPaperclip,
  faPhone,
  faEnvelope,
  faCheckSquare,
  faSquareRegular,
  faSearch,
  faComment,
  faFileAlt,
  faPlus,
  faTrash,
  faEdit,
  faLink,
  faTag,
  faTags,
  faUserTag,
  faUsers,
  faPrint,
  faComments,
  faGlobeAmericas,
  faClockRegular,
  faTachometerAlt,
  faMinusSquare,
  faClipboard,
  faClipboardCheck,
  faCog,
  faItalic,
  faBold,
  faUnderline,
  faListUl,
  faListOl,
) // Include needed icons.

export const icons = {
  iconfont: 'faSvg',
  values: {
    // Icons used by Vuetify components
    prev: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'chevron-left'],
      },
    },
    next: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'chevron-right'],
      },
    },
    dropdown: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'caret-down'],
      },
    },
    expand: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'chevron-down'],
      },
    },
    checkboxOff: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'square'],
      },
    },
    checkboxOn: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'check-square'],
      },
    },
    delete: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'times-circle'],
      },
    },
    clear: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'times-circle'],
      },
    },
    sort: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'sort-up'],
      },
    },
    menu: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'bars'],
      },
    },
    // Icons used in application
    circle: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'circle'],
      },
    },
    circleRegular: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'circle'],
      },
    },
    calendarAlt: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'calendar-alt'],
      },
    },
    calendarPlus: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'calendar-plus'],
      },
    },
    calendarMinus: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'calendar-minus'],
      },
    },
    calendar: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'calendar'],
      },
    },
    newspaper: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'newspaper'],
      },
    },
    user: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'user'],
      },
    },
    addressCard: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'address-card'],
      },
    },
    sortUp: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'sort-up'],
      },
    },
    signOutAlt: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'sign-out-alt'],
      },
    },
    key: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'key'],
      },
    },
    times: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'times'],
      },
    },
    timesCircle: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'times-circle'],
      },
    },
    chevronDown: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'chevron-down'],
      },
    },
    bell: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'bell'],
      },
    },
    paperclip: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'paperclip'],
      },
    },
    phone: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'phone'],
      },
    },
    envelope: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'envelope'],
      },
    },
    checkSquare: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'check-square'],
      },
    },
    squareRegular: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'square'],
      },
    },
    search: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'search'],
      },
    },
    comment: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'comment'],
      },
    },
    fileAlt: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'file-alt'],
      },
    },
    plus: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'plus'],
      },
    },
    trash: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'trash'],
      },
    },
    edit: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'edit'],
      },
    },
    link: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'link'],
      },
    },
    tag: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'tag'],
      },
    },
    tags: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'tags'],
      },
    },
    userTag: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'user-tag'],
      },
    },
    users: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'users'],
      },
    },
    print: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'print'],
      },
    },
    comments: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'comments'],
      },
    },
    globeAmericas: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'globe-americas'],
      },
    },
    clockRegular: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'clock'],
      },
    },
    tachometerAlt: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'tachometer-alt'],
      },
    },
    minusSquare: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'minus-square'],
      },
    },
    clipboardCheck: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'clipboard-check'],
      },
    },
    clipboard: {
      component: FontAwesomeIcon,
      props: {
        icon: ['far', 'clipboard'],
      },
    },
    cog: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'cog'],
      },
    },
    italic: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'italic'],
      },
    },
    bold: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'bold'],
      },
    },
    underline: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'underline'],
      },
    },
    uList: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'list-ul'],
      },
    },
    oList: {
      component: FontAwesomeIcon,
      props: {
        icon: ['fas', 'list-ol'],
      },
    },
  },
}
