<template>
  <v-menu
    v-model="open"
    :close-on-content-click="false"
    offset-y
    max-height="300"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :title="t('title')"
        small
        icon
        class="ma-1"
        v-on="on"
        @click.stop
      >
        <v-icon
          :color="hasSelected ? 'primary' : undefined"
          small
        >
          {{ icon }}
        </v-icon>
      </v-btn>
    </template>
    <v-card
      dark
      tile
    >
      <v-card-actions>
        {{ t('title') }}
        <v-spacer />
        <v-btn
          :title="t('close')"
          icon
          small
          class="ml-2"
          @click="closeMenu"
        >
          <v-icon small>$vuetify.icons.times</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      tile
    >
      <v-card-text v-if="itemsLoading">
        <div class="d-flex align-center">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mr-3"
          />
          <p class="mb-0">{{ t('loading') }}</p>
        </div>
      </v-card-text>
      <v-card-text v-else-if="!itemsLoading && items.length === 0">
        <p :class="{ 'mb-0': !createItemRoute }">{{ t('noItems' ) }}</p>
        <v-btn
          v-if="createItemRoute"
          :to="createItemRoute"
          color="primary"
          tile
        >
          {{ t('createItem' ) }}
        </v-btn>
      </v-card-text>
    </v-card>

    <v-list v-if="!itemsLoading && items.length > 0">
      <v-list-item-group
        :value="selected"
        multiple
      >
        <template v-for="item in items">
          <v-list-item
            :key="item.id"
            :value="item.id"
            :disabled="item.loading"
            @click="toggleState(item)"
          >
            <template v-slot:default="{ active }">
              <v-list-item-content>
                <v-list-item-title v-text="labelAccessor(item)" />
              </v-list-item-content>
              <v-list-item-action>
                <v-checkbox
                  :input-value="active"
                  :true-value="item"
                />
              </v-list-item-action>
            </template>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { camelToKebab, camelToSnake } from '@/utils/vuex'

export default {
  name: 'AssignButton',
  props: {
    affair: {
      type: Object,
      default () {
        return {}
      },
    },
    module: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    createItemRoute: {
      type: Object,
      default () {
        return null
      },
    },
    labelAccessor: {
      type: Function,
      default (item) {
        return item.name
      },
    },
  },
  data () {
    return {
      open: false,
    }
  },
  computed: {
    hasSelected () {
      return this.affair[this.relationName]?.length > 0
    },
    selected () {
      return this.affair[this.relationName]?.map(rel => rel.id)
    },
    storeName () {
      return camelToKebab(this.module)
    },
    relationName () {
      return camelToSnake(this.module)
    },
    items () {
      return this.$store.state[this.storeName].items
    },
    itemsLoading () {
      return this.$store.state[this.storeName].itemsLoading
    },
  },
  methods: {
    closeMenu () {
      this.open = false
    },
    toggleState (item) {
      this.$store.dispatch('affairs/toggleRelation', { affairId: this.affair.id, item, module: this.module })
    },
    t (key) {
      return this.$t(`Core.AssignButton.${this.module}.${key}`)
    },
  },
}
</script>
