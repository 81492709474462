export default {
  events: [],
  loading: true,
  filtersExpanded: undefined,
  filterItemsFetched: false,
  filters: [
    {
      name: 'subscribed',
      type: 'select',
      label: 'Core.Filters.subscriptionState.label',
      itemText: 'text',
      itemValue: 'value',
      items: [
        { value: true, text: 'Core.Filters.subscriptionState.subscribed', translate: true },
        { value: false, text: 'Core.Filters.subscriptionState.unsubscribed', translate: true },
      ],
      value: null,
      default: null,
      persist: 'calendar-filter-subscribed',
    },
    {
      name: 'event_type',
      type: 'treeselect',
      label: 'Core.Filters.eventType.label',
      itemText: 'name',
      itemValue: 'id',
      colorizeItem: true,
      items: [],
      itemsFlat: [],
      value: [],
      default: [],
      persist: 'calendar-filter-event_type',
    },
  ],
}
