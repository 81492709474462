<template>
  <div>
    <div class="d-flex align-center justify-space-between">
      <v-btn
        color="primary"
        class="ma-2"
        icon
        :disabled="loading"
        @click="$refs.calendar.prev()"
      >
        <v-icon>
          $vuetify.icons.prev
        </v-icon>
      </v-btn>
      <p class="h4 mb-0">
        <span class="text-no-wrap">{{ $options.filters.formatDate(start, $t('Common.dateFormatLong')) }}</span>
        <span v-if="start !== end">
          -
          <span class="text-no-wrap">{{ $options.filters.formatDate(end, $t('Common.dateFormatLong')) }}</span>
        </span>
      </p>
      <v-btn
        color="primary"
        class="ma-2"
        icon
        :disabled="loading"
        @click="$refs.calendar.next()"
      >
        <v-icon>
          $vuetify.icons.next
        </v-icon>
      </v-btn>
    </div>
    <v-calendar
      v-if="type"
      ref="calendar"
      class="events-widget"
      v-model="currentInternal"
      :weekdays="weekday"
      :type="type"
      :events="calendarEvents"
      :event-more="false"
      :event-text-color="getEventTextColor"
      @change="rangeChange"
      @click:event="onEventClick"
    >
      <template v-slot:day-label-header="{ day }">
        <div class="day-label-inner mx-auto d-flex align-center justify-center rounded-circle">{{ day }}</div>
      </template>
      <template v-slot:event="{ event }">
        <div
          class="px-1"
          :class="{ 'is-blanco': event.eventRaw.affair.is_blanco }"
          :title="event.name"
          v-html="event.nameHtml"
        />
      </template>
    </v-calendar>
  </div>
</template>

<script>
import moment from 'moment';
import { VUETIFY_DATE_FORMAT } from '@/utils/dateHelper';
import { DEFAULT_TEXT_COLOR, getContrastColor } from '@/utils/colorHelper';

export default {
  name: 'Calendar',
  data: () => ({
    weekday: [1, 2, 3, 4, 5, 6, 0],
    start: '',
    end: '',
  }),
  props: {
    value: {
      type: String,
    },
    type: {
      type: String,
    },
    events: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    calendarEvents () {
      return this.events.map((event) => {
        return {
          name: this.getEventName(event),
          nameHtml: this.getEventNameHtml(event),
          start: moment(event.eventdate).format(VUETIFY_DATE_FORMAT),
          end: event.enddate ? moment(event.enddate).format(VUETIFY_DATE_FORMAT) : null,
          color: event.event_type ? event.event_type.color : null,
          eventRaw: event,
        }
      })
    },
    currentInternal: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
  methods: {
    getEventName (event) {
      let eventName = ''
      if (event.event_type) {
        eventName = `${event.event_type.name}`
      }
      if (!event.affair.is_blanco) {
        eventName = `${eventName ? `- ${eventName} - ` : ''}${event.affair.title}`
        if (event.affair.affair_number) {
          eventName = `${eventName} (${event.affair.affair_number})`
        }
      }
      return eventName ? eventName : '-'
    },
    getEventNameHtml (event) {
      let eventName = ''
      if (event.event_type) {
        eventName = `<span class="font-weight-bold">${event.event_type.name}</span>`
      }
      if (!event.affair.is_blanco) {
        eventName = `${eventName ? `${eventName} ` : ''}${event.affair.title}`
        if (event.affair.affair_number) {
          eventName = `${eventName} (${event.affair.affair_number})`
        }
      }
      return eventName ? eventName : '-'
    },
    getEventTextColor (event) {
      return event.color ? getContrastColor(event.color) : DEFAULT_TEXT_COLOR;
    },
    rangeChange (range) {
      this.start = range.start.date
      this.end = range.end.date
      this.$emit('range-change', this.start, this.end)
    },
    onEventClick (event) {
      const { eventRaw } = event.event
      if (!eventRaw.affair.is_blanco) {
        this.$router.push({ name: 'affair-detail', params: { id: eventRaw.affair.id }, hash: `#event-${eventRaw.id}` })
      }
    },
  },
}
</script>
