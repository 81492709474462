<template>
  <!-- eslint-disable vue/no-mutating-props -->
  <!-- TODO: remove props mutation -->
  <v-dialog
    v-model="open"
    persistent
    max-width="500px"
  >
    <v-form
      ref="issueForm"
      v-model="valid"
      @submit.prevent="onFormSubmit"
    >
      <v-card>
        <v-card-title
          class="headline grey lighten-2"
          primary-title
        >
          {{ title }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            v-if="issue"
            v-model="issue.name"
            :label="nameLabel"
            :rules="issueNameRules"
            autofocus
          />
        </v-card-text>

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            tile
            :loading="loading"
            :disabled="!valid || loading"
            type="submit"
          >
            {{ okText }}
          </v-btn>
          <v-btn
            text
            tile
            :disabled="loading"
            @click="onCancelClick"
          >
            {{ cancelText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
  <!-- eslint-enable -->
</template>

<script>
export default {
  name: 'IssueDialog',
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    okText: {
      type: String,
      default: '',
    },
    cancelText: {
      type: String,
      default: '',
    },
    nameRequiredText: {
      type: String,
      default: '',
    },
    nameLabel: {
      type: String,
      default: '',
    },
    issue: {
      type: Object,
      default () {
        return {}
      },
    },
  },
  data () {
    let initialized = false

    return {
      valid: true,
      issueNameRules: [
        v => {
          // do not validate when dialog gets opened
          if (!initialized) {
            initialized = true
            return true
          }
          return !!v || this.nameRequiredText
        },
      ],
    }
  },
  watch: {
    open: function (after) {
      // reset form validation when dialog gets opened
      if (after && this.$refs.issueForm) {
        this.$refs.issueForm.resetValidation()
      }
    },
  },
  methods: {
    onFormSubmit () {
      this.$emit('submit')
    },
    onCancelClick () {
      this.$emit('cancel')
    },
  },
}
</script>
