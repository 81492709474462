<template>
  <v-card>
    <v-card-title class="justify-space-between">
      <div class="mr-3">
        {{ title }}
        <v-progress-circular
          v-if="loading"
          size="26"
          width="3"
          color="primary"
          class="ml-3"
          indeterminate
        />
      </div>
      <div>
        <slot name="actions" />
      </div>
    </v-card-title>
    <v-card-text class="pa-0">
      <slot></slot>
    </v-card-text>
    <v-card-actions v-if="action && action.label">
      <v-btn
        color="primary"
        tile
        text
        class="mx-auto"
        :to="action.to"
      >
        {{ action.label }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'BaseWidget',
  props: {
    title: {
      type: String,
    },
    loading: {
      type: Boolean,
      default () {
        return false
      },
    },
    action: {
      type: Object,
    },
  },
}
</script>
