import http from '@/plugins/axios'
import moment from 'moment'

export const api = path => {
  return process.env.VUE_APP_API_URL + path
}

export const perpareFilterParams = filters => {
  return filters.reduce((filterParams, filter) => {
    if (Array.isArray(filter.value)) {
      if (filter.value.length > 0) {
        filterParams[filter.name] = filter.value.join(',')
      }
    } else {
      if (filter.value !== undefined && filter.value !== null) {
        filterParams[filter.name] = filter.value
      }
    }
    return filterParams
  }, {})
}

const isArrayFilter = (filter) => Array.isArray(filter.value)
const isBooleanFilter = (filter) => filter.value === null || typeof filter.value === 'boolean'

export const decodeFilterParam = (filter, param) => {
  if (isArrayFilter(filter)) {
    return param.split(',').map((id) => parseInt(id)).filter((id) => !isNaN(id))
  } else if (isBooleanFilter(filter)) {
    if (param === '1' || param === 'true' || param === true) {
      return true
    } else if (param === '0' || param === 'false' || param === false) {
      return false
    }
    return null
  }
  return param
}

export const nameCompareFn = (a, b) => {
  return a.name.localeCompare(b.name, 'de', { sensitivity: 'base' })
}

export const dateCompareFn = (a, b) => {
  return moment(b.created_at).diff(moment(a.created_at))
}

export const filter = {
  SET_FILTER_EXPANDED (state, expanded) {
    state.filtersExpanded = expanded
  },
  SET_FILTER_VALUE (state, { filterName, value }) {
    const filter = state.filters.find(f => f.name === filterName)
    if (filter) {
      filter.value = value
    }
  },
  RESET_FILTER_VALUES (state) {
    state.filters.forEach(filter => {
      filter.value = filter.default
    })
  },
  SET_FILTER_ITEMS (state, filters) {
    Object.keys(filters).forEach(filterName => {
      const filter = state.filters.find(f => f.name === filterName)
      if (filter) {
        filter.items = filters[filterName]
        if (filter.type === 'treeselect') {
          filter.itemsFlat = filters[`${filterName}_flat`]
        }
      }
    })
  },
  SET_FILTER_ITEMS_FETCHED (state, fetched) {
    state.filterItemsFetched = fetched
  },
  SET_FILTER_VALUES_AND_DEFAULTS (state, { defaults = {}, settings = {} }) {
    state.filters = state.filters.map((filter) => {
      if (filter.persist) {
        const defaultValue = defaults ? defaults[`${filter.persist}`] : undefined
        const value = settings && settings[`${filter.persist}`] !== undefined ? settings[`${filter.persist}`] : defaultValue
        return {
          ...filter,
          ...((defaultValue !== undefined) && { default: defaultValue }),
          ...((value !== undefined) && { value: value }),
        }
      }
      return filter
    })
  },
  SET_FILTER_ITEM (state, { name, items }) {
    const filter = state.filters.find(f => f.name === name)
    if (filter) {
      filter.items = items
      // get all ids of new items
      const ids = items.map(i => i.id)
      // intersect with set values, to remove ids if they get deleted
      filter.value = filter.value.filter(v => ids.indexOf(v) > -1)
    }
  },
}

export const filterActions = {
  setFilterValuesFromQuery ({ commit, state }, { query }) {
    // Get non-empty params which match a filter
    const filtersInQuery = state.filters.filter((f) => Object.keys(query).filter((queryKey) => query[queryKey]).includes(f.name))
    if (filtersInQuery.length === 0) {
      return
    }

    commit('RESET_FILTER_VALUES')
    filtersInQuery.forEach((filter) => {
      commit('SET_FILTER_VALUE', { filterName: filter.name, value: decodeFilterParam(filter, query[filter.name]) })
    })
  },
}

// convert CamelCaseString to camel-case-string
export const camelToKebab = value => value.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
// convert CamelCaseString to camel_case_string
export const camelToSnake = value => value.replace(/([a-z0-9])([A-Z])/g, '$1_$2').toLowerCase()

const getItems = module => async ({ commit }) => {
  commit('setItemsLoading', true)
  try {
    const resource = camelToKebab(module)
    const res = await http.get(api(`/${resource}`))
    commit('setItems', res.data)
  } catch (err) {
    commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
  } finally {
    commit('setItemsLoading', false)
  }
}

const addItem = module => async ({ commit, state }, item) => {
  try {
    const resource = camelToKebab(module)
    const res = await http.post(api(`/${resource}`), item)
    const items = [...state.items, res.data].sort(nameCompareFn)
    commit('setItems', items)
    const args = { name: resource, items }
    const opts = { root: true }
    commit('affairs/SET_FILTER_ITEM', args, opts)
    commit('events-future/SET_FILTER_ITEM', args, opts)
    commit('events-past/SET_FILTER_ITEM', args, opts)
  } catch (err) {
    commit('app/setSnack', { message: `Error.${module}.addItem`, color: 'error', translate: true }, { root: true })
  }
}

const updateItem = module => async ({ commit, state }, item) => {
  try {
    const resource = camelToKebab(module)
    const res = await http.put(api(`/${resource}/${item.id}`), item)
    const items = state.items.map(i => {
      return i.id !== res.data.id ? i : res.data
    }).sort(nameCompareFn)
    commit('setItems', items)
    const args = { name: module, items }
    const opts = { root: true }
    commit('affairs/SET_FILTER_ITEM', args, opts)
    commit('events-future/SET_FILTER_ITEM', args, opts)
    commit('events-past/SET_FILTER_ITEM', args, opts)
  } catch (err) {
    commit('app/setSnack', { message: `Error.${module}.updateItem`, color: 'error', translate: true }, { root: true })
  }
}

const deleteItem = module => async ({ commit, state }, item) => {
  try {
    const resource = camelToKebab(module)
    await http.delete(api(`/${resource}/${item.id}`))
    const items = state.items.filter(i => i.id !== item.id)
    commit('setItems', items)
    const args = { name: resource, items }
    const opts = { root: true }
    commit('affairs/SET_FILTER_ITEM', args, opts)
    commit('events-future/SET_FILTER_ITEM', args, opts)
    commit('events-past/SET_FILTER_ITEM', args, opts)
  } catch (err) {
    commit('app/setSnack', { message: `Error.${module}.deleteItem`, color: 'error', translate: true }, { root: true })
  }
}

export const resourceActions = module => ({
  getItems: getItems(module),
  addItem: addItem(module),
  updateItem: updateItem(module),
  deleteItem: deleteItem(module),
})

export const resourceState = {
  items: [],
  itemsLoading: false,
}

export const resourceMutations = {
  setItems (state, items) {
    state.items = items
  },
  setItemLoading (state, { itemId, loading }) {
    state.items = state.items.map(item => {
      return item.id !== itemId ? item : { ...item, loading }
    })
  },
  setItemsLoading (state, loading) {
    state.itemsLoading = loading
  },
}
