/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
  {
    path: '/',
    meta: { layout: 'default' },
    name: 'dashboard',
    view: 'Dashboard',
  },
  {
    path: '/login',
    meta: { layout: 'fullscreen' },
    name: 'login',
    view: 'Login',
  },
  {
    path: '/affairs/:id',
    meta: { layout: 'default' },
    name: 'affair-detail',
    // Relative to /src/views
    view: 'AffairDetail',
  },
  {
    path: '/affairs',
    meta: { layout: 'default' },
    name: 'affairs',
    // Relative to /src/views
    view: 'Affairs',
  },
  {
    path: '/calendar',
    meta: { layout: 'default' },
    name: 'calendar',
    // Relative to /src/views
    view: 'Calendar',
  },
  {
    path: '/events-past',
    meta: { layout: 'default' },
    name: 'events-past',
    view: 'EventsPast',
  },
  {
    path: '/events-future',
    meta: { layout: 'default' },
    name: 'events-future',
    view: 'EventsFuture',
  },
  {
    path: '/user',
    meta: { layout: 'default' },
    name: 'user',
    view: 'User',
  },
  {
    path: '/issues',
    meta: { layout: 'default' },
    name: 'issues',
    view: 'Issues',
  },
  {
    path: '/404',
    meta: { layout: 'default' },
    name: '404',
    view: '404',
  },
]
