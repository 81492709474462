export default {
  hasPermission: state => permission => {
    if (!state.user) {
      return false
    }
    return state.user.user_permissions.filter(p => p === permission).length > 0
  },
  user: state => state.user,
  otherUsers: state => state.otherUsers,
}
