import http from '@/plugins/axios'
import { api, perpareFilterParams, camelToKebab, camelToSnake, decodeFilterParam } from '@/utils/vuex'

export default {
  async getAffair ({ commit, state }, affairId) {
    commit('SET_AFFAIR_LOADING', true)
    commit('SET_AFFAIR_ERROR', null)
    const affairLoaded = state.affairs.data.find(affair => affair.id === affairId)
    if (!affairLoaded) {
      try {
        const res = await http.get(api(`/affairs/${affairId}`))
        commit('SET_AFFAIRS', { data: [res.data] })
      } catch (err) {
        commit('SET_AFFAIR_ERROR', err)
      } finally {
        commit('SET_AFFAIR_LOADING', false)
      }
    } else {
      commit('SET_AFFAIR_LOADING', false)
    }
  },
  async getAffairs ({ commit, state }) {
    const { page, itemsPerPage, sortDesc, sortBy } = state.options
    const filterParams = perpareFilterParams(state.filters)
    const q = state.query
    commit('SET_AFFAIRS_LOADING', true)
    try {
      const res = await http.get(
        api('/affairs'),
        { params: { page, rowsPerPage: itemsPerPage, sortBy, descending: sortDesc, q, ...filterParams } },
      )
      commit('SET_AFFAIRS', res.data)
      commit('SET_AFFAIRS_SERVER_ITEMS_LENGTH', res.data.total)
    } catch (err) {
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    } finally {
      commit('SET_AFFAIRS_LOADING', false)
    }
  },
  async subscribeMe ({ commit }, affairId) {
    try {
      await http.post(api(`/affairs/${affairId}/subscribers/me`))
      return true
    } catch (err) {
      commit('app/setSnack', { message: 'Error.changeSubscription', color: 'error', translate: true }, { root: true })
    }
    return false
  },
  async unsubscribeMe ({ commit }, affairId) {
    try {
      await http.delete(api(`/affairs/${affairId}/subscribers/me`))
      return true
    } catch (err) {
      commit('app/setSnack', { message: 'Error.changeSubscription', color: 'error', translate: true }, { root: true })
    }
    return false
  },
  async subscribe ({ commit }, { affairId, subscriber }) {
    try {
      await http.post(
        api(`/affairs/${affairId}/subscribers`),
        { user_id: subscriber.id },
      )
      commit('ADD_AFFAIRS_SUBSCRIBER', { affairId, subscriber })
    } catch (err) {
      commit('app/setSnack', { message: 'Error.changeSubscription', color: 'error', translate: true }, { root: true })
    }
  },
  async unsubscribe ({ commit }, { affairId, subscriber }) {
    try {
      await http.delete(
        api(`/affairs/${affairId}/subscribers`),
        { data: { user_id: subscriber.id } },
      )
      commit('REMOVE_AFFAIRS_SUBSCRIBER', { affairId, subscriber })
    } catch (err) {
      commit('app/setSnack', { message: 'Error.changeSubscription', color: 'error', translate: true }, { root: true })
    }
  },
  async getFilterItems ({ commit, state }) {
    // Only load filter items once
    if (state.filterItemsFetched) {
      return
    }

    try {
      const res = await http.get(api('/affairs/filters'))
      commit('SET_FILTER_ITEMS', res.data.filters)
      commit('SET_FILTER_ITEMS_FETCHED', true)
    } catch (err) {
      commit('SET_FILTER_ITEMS_FETCHED', false)
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    }
  },
  async toggleRelation ({ state, commit }, { affairId, item, module }) {
    const affair = state.affairs.data.find(affair => affair.id === affairId)
    const relation = camelToSnake(module)
    const resource = camelToKebab(module)
    const itemExists = !!affair[relation].find(rel => rel.id === item.id)
    try {
      commit(`${resource}/setItemLoading`, { itemId: item.id, loading: true }, { root: true })
      if (itemExists) {
        await http.delete(api(`/affairs/${affairId}/${resource}/${item.id}`))
        commit('REMOVE_AFFAIRS_RELATION', { affairId, item, relation })
      } else {
        const res = await http.post(api(`/affairs/${affairId}/${resource}`), { id: item.id })
        commit('ADD_AFFAIRS_RELATION', { affairId, item: res.data, relation })
      }
    } catch (err) {
      commit('app/setSnack', { message: `Error.affair${module}${itemExists ? 'Remove' : 'Add'}`, color: 'error', translate: true }, { root: true })
    } finally {
      commit(`${resource}/setItemLoading`, { itemId: item.id, loading: false }, { root: true })
    }
  },
  async createComment ({ commit }, { affairId, comment }) {
    try {
      const res = await http.post(api(`/affairs/${affairId}/comments`), comment)
      commit('ADD_COMMENT', { affairId, comment: res.data })
      commit('app/setSnack', { message: 'Core.CommentSection.createSuccess', color: 'success', translate: true }, { root: true })
    } catch (e) {
      commit('app/setSnack', { message: 'Error.createComment', color: 'error', translate: true }, { root: true })
    }
  },
  async deleteComment ({ commit }, { comment }) {
    try {
      await http.delete(api(`/affairs/${comment.affair_id}/comments/${comment.id}`))
      commit('REMOVE_AFFAIRS_RELATION', { affairId: comment.affair_id, item: comment, relation: 'comments' })
      commit('app/setSnack', { message: 'Core.Comment.deleteSuccess', color: 'success', translate: true }, { root: true })
    } catch (e) {
      commit('app/setSnack', { message: 'Error.deleteComment', color: 'error', translate: true }, { root: true })
    }
  },
  async updateComment ({ commit }, { comment }) {
    try {
      const res = await http.put(api(`/affairs/${comment.affair_id}/comments/${comment.id}`), comment)
      commit('UPDATE_COMMENT', { affairId: comment.affair_id, comment: res.data })
      commit('app/setSnack', { message: 'Core.Comment.updateSuccess', color: 'success', translate: true }, { root: true })
    } catch (e) {
      commit('app/setSnack', { message: 'Error.updateComment', color: 'error', translate: true }, { root: true })
    }
  },
  async getNoteTypes ({ commit }) {
    try {
      const res = await http.get(api('/affairs/notetypes'))
      commit('SET_NOTE_TYPES', { noteTypes: res.data })
    } catch (e) {
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    }
  },
  setFilterValuesAndQueryFromQuery ({ commit, state }, { query }) {
    // Get non-empty params which match a filter
    const filtersInQuery = state.filters.filter((f) => Object.keys(query).filter((queryKey) => query[queryKey]).includes(f.name))

    // Do not reset fields if no matching param was found
    if (filtersInQuery.length === 0 && !query.q) {
      return
    }

    // Reset all fields
    commit('RESET_FILTER_VALUES')
    commit('RESET_QUERY')

    filtersInQuery.forEach((filter) => {
      commit('SET_FILTER_VALUE', { filterName: filter.name, value: decodeFilterParam(filter, query[filter.name]) })
    })

    if (query.q) {
      commit('SET_QUERY', query.q)
    }
  },
}
