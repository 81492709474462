import moment from 'moment';

export const VUETIFY_DATE_FORMAT = 'YYYY-MM-DD'
export const READABLE_DATE_FORMAT = 'DD.MM.YYYY'

export const today = (format = VUETIFY_DATE_FORMAT) => {
  return moment().format(format)
}

export const firstDayOfWeek = (format = VUETIFY_DATE_FORMAT, currentDate = undefined) => {
  return moment(currentDate).startOf('isoWeek').format(format)
}

export const lastDayOfWeek = (format = VUETIFY_DATE_FORMAT, currentDate = undefined) => {
  return moment(currentDate).endOf('isoWeek').format(format)
}

export const getFormattedEventdate = (start, end) => {
  let formattedDate = ''
  if (start) {
    formattedDate = moment(start).format(READABLE_DATE_FORMAT)
    if (end) {
      formattedDate = `${formattedDate} - ${moment(end).format(READABLE_DATE_FORMAT)}`
    }
  } else {
    formattedDate = '-'
  }
  return formattedDate
}
