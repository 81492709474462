<template>
  <div class="comment-section">
    <h2 id="comments">{{ $t('Core.CommentSection.title') }}</h2>
    <v-expansion-panels
      v-model="newCommentExpanded"
      class="d-print-none mb-6"
      :loading="loading"
      :disabled="loading"
    >
      <v-expansion-panel

      >
        <v-expansion-panel-header>
          <span class="font-weight-bold">
            {{ $t('Core.CommentSection.addCommentTitle') }}
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form
            @submit.prevent="onCreateComment"
          >
            <CoreRichTextEditor
              v-model="newComment.content"
              @empty="isEmpty => valid = !isEmpty"
            />
            <v-switch
              v-model="newComment.is_public"
              @change="onIsPublicChange"
              :label="$t('Core.CommentSection.isPublicLabel')"
            />
            <v-select
              v-if="users && !newComment.is_public"
              v-model="newComment.allowed_users_ids"
              chips
              small-chips
              deletable-chips
              multiple
              :label="$t('Core.CommentSection.shareComments')"
              :items="users"
              item-text="name"
              item-value="id"
            />
            <v-select
              v-model="newComment.note_type_id"
              :items="noteTypes"
              item-text="name"
              item-value="id"
              :clearable="true"
              :label="$t('Core.CommentSection.noteType')"
            />
          </v-form>
          <v-btn
            :disabled="!valid"
            tile
            color="primary"
            type="submit"
            @click="onCreateComment"
          >
            {{ $t('Core.CommentSection.create') }}
          </v-btn>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider
      class="mb-4 d-print-none"
    />
    <div class="mb-4">
      <v-select
          v-model="noteTypeFilter"
          :items="noteTypes"
          item-text="name"
          item-value="id"
          :clearable="true"
          :label="$t('Core.CommentSection.noteTypeFilter')"
        />
    </div>
    <core-comment
      v-for="comment in comments"
      :key="comment.id"
      :comment="comment"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'CommentSection',
  props: {
    affair: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      valid: false,
      loading: false,
      newComment: {
        content: '',
        is_public: false,
        allowed_users_ids: [],
        note_type_id: null,
      },
      noteTypeFilter: null,
      newCommentExpanded: undefined,
    }
  },
  computed: {
    ...mapGetters('affairs', [
      'noteTypes',
    ]),
    ...mapGetters('auth', [
      'otherUsers',
    ]),
    comments () {
      if (!this.affair.comments) {
        return [];
      }
      if (!this.noteTypeFilter) {
        return this.affair.comments;
      }
      return this.affair.comments.filter(
        comment => comment.note_type_id === this.noteTypeFilter,
      );
    },
    users () {
      return this.otherUsers.map((user) =>({
        id: user.id,
        name: `${user.name} ${user.surname} (${user.email})`,
      }))
    },
  },
  methods: {
    ...mapActions('affairs', ['createComment']),
    async onCreateComment () {
      if (this.valid) {
        this.loading = true
        await this.createComment({
          comment: this.newComment,
          affairId: this.affair.id,
        })
        this.newComment.content = ''
        this.newComment.is_public = false
        this.newComment.note_type_id = null
        this.newComment.allowed_users_ids = []
        this.valid = false
        this.loading = false
        this.newCommentExpanded = undefined
      }
    },
    onIsPublicChange () {
      if (this.newComment.is_public) {
        this.newComment.allowed_users_ids = []
      }
    },
  },
}
</script>
