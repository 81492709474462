import { nameCompareFn, dateCompareFn, filter } from '@/utils/vuex'

export default {
  SET_AFFAIR_LOADING (state, affairLoading) {
    state.affairLoading = affairLoading
  },
  SET_AFFAIR_ERROR (state, error) {
    state.affairError = error
  },
  SET_AFFAIRS (state, affairs) {
    state.affairs = affairs
  },
  SET_AFFAIRS_LOADING (state, loading) {
    state.loading = loading
  },
  SET_AFFAIRS_SERVER_ITEMS_LENGTH (state, serverItemsLength) {
    state.serverItemsLength = serverItemsLength
  },
  SET_AFFAIRS_OPTIONS (state, options) {
    state.options = options
  },
  SET_AFFAIRS_PAGE (state, page) {
    state.options.page = page
  },
  SET_QUERY (state, query) {
    state.query = query
  },
  RESET_QUERY (state) {
    state.query = ''
  },
  SET_AFFAIRS_SUBSCRIBED (state, { affairId, subscribed }) {
    const affair = state.affairs.data.find(affair => affair.id === affairId)
    if (affair) {
      affair.subscribed = subscribed
    }
  },
  ADD_AFFAIRS_SUBSCRIBER (state, { affairId, subscriber }) {
    const affair = state.affairs.data.find(a => a.id === affairId)
    if (affair) {
      affair.subscribers = [
        ...affair.subscribers,
        subscriber,
      ]
    }
  },
  REMOVE_AFFAIRS_SUBSCRIBER (state, { affairId, subscriber }) {
    const affair = state.affairs.data.find(a => a.id === affairId)
    if (affair) {
      affair.subscribers = affair.subscribers.filter(s => s.id !== subscriber.id)
    }
  },
  ADD_AFFAIRS_RELATION (state, { affairId, item, relation }) {
    const affair = state.affairs.data.find(affair => affair.id === affairId)
    if (affair) {
      affair[relation] = [...affair[relation], item].sort(nameCompareFn)
    }
  },
  REMOVE_AFFAIRS_RELATION (state, { affairId, item, relation }) {
    const affair = state.affairs.data.find(affair => affair.id === affairId)
    if (affair) {
      affair[relation] = affair[relation].filter(i => i.id !== item.id)
    }
  },
  ...filter,
  ADD_COMMENT (state, { affairId, comment }) {
    const affair = state.affairs.data.find(affair => affair.id === affairId)
    if (affair) {
      affair.comments = [...affair.comments, comment].sort(dateCompareFn)
    }
  },
  UPDATE_COMMENT (state, { affairId, comment }) {
    const affair = state.affairs.data.find(affair => affair.id === affairId)
    if (affair) {
      affair.comments = affair.comments.map(c => c.id === comment.id ? comment : c)
    }
  },
  SET_NOTE_TYPES (state, { noteTypes }) {
    state.noteTypes = noteTypes
  },
}
