<template>
  <v-btn
    small
    :disabled="loading"
    @click="onClick"
  >
    <v-icon x-small class="mr-1">
      $vuetify.icons.calendarAlt
    </v-icon>
    {{ $t('Core.CalendarTodayButton.today') }}
  </v-btn>
</template>

<script>
export default {
  name: 'CalendarTodayButton',
  props: {
    loading: {
      type: Boolean,
    },
  },
  methods: {
    onClick (e) {
      this.$emit('click', e)
    },
  },
}
</script>
