var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"affair-list",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.optionsPassthrough,"server-items-length":_vm.serverItemsLength,"loading":_vm.loading,"footer-props":{
    itemsPerPageOptions: _vm.itemsPerPageOptions,
  },"expanded":_vm.expanded,"mobile-breakpoint":0,"item-key":"id","show-expand":"","item-class":_vm.itemRowBackground},on:{"update:options":function($event){_vm.optionsPassthrough=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Affairs.loading'))+" ")]},proxy:true},{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('Affairs.noData'))+" ")]},proxy:true},{key:"item.submission_date",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.submission_date ? _vm.$options.filters.formatDate(item.submission_date) : '-')+" ")]}},{key:"item.affair_number",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.affair_number ? item.affair_number : '-')+" ")]}},{key:"item.affair_type_name",fn:function(ref){
  var item = ref.item;
return [_vm._v(" "+_vm._s(item.affair_type ? item.affair_type.name : '-')+" ")]}},{key:"item.actions",fn:function(ref){
  var item = ref.item;
return [_c('div',{staticClass:"actions",on:{"click":function($event){$event.stopPropagation();}}},[(_vm.hasPermission(_vm.permissions.COMMENTS))?_c('core-comment-section-button',{attrs:{"affair":item}}):_vm._e(),(_vm.hasPermission(_vm.permissions.AFFAIRS_SUBSCRIBERS))?_c('core-subscribe-button',{attrs:{"affair":item},on:{"subscribed":function($event){return _vm.onSubscribed(item.id)},"unsubscribed":function($event){return _vm.onUnsubscribed(item.id)}}}):_vm._e(),(_vm.hasPermission(_vm.permissions.SUBSCRIBERS))?_c('core-assign-button',{attrs:{"affair":item,"module":"Subscribers","icon":"$vuetify.icons.users","label-accessor":function (item) { return ((item.name) + " " + (item.surname)); }}}):_vm._e(),(_vm.hasPermission(_vm.permissions.GLOBAL_ISSUES))?_c('core-assign-button',{attrs:{"affair":item,"create-item-route":{ name: 'issues' },"module":"GlobalIssues","icon":"$vuetify.icons.userTag"}}):_vm._e(),(_vm.hasPermission(_vm.permissions.AFFAIRS_ISSUES))?_c('core-assign-button',{attrs:{"affair":item,"create-item-route":{ name: 'issues' },"module":"Issues","icon":"$vuetify.icons.tag"}}):_vm._e()],1)]}},{key:"expanded-item",fn:function(ref){
  var headers = ref.headers;
  var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Affairs.politicalLevel')}},[_vm._v(" "+_vm._s(item.political_level ? item.political_level.name : '-')+" ")]),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Affairs.author')}},[_vm._v(" "+_vm._s(item.author ? item.author.name : '-')+" ")]),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Affairs.topics')}},[(item.topics && item.topics.length > 0)?_c('div',_vm._l((item.topics),function(topic){return _c('v-chip',{key:topic.id,staticClass:"ma-1"},[_vm._v(" "+_vm._s(topic.name)+" ")])}),1):[_vm._v("-")]],2),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Affairs.globalIssues')}},[(item.global_issues && item.global_issues.length > 0)?_c('div',_vm._l((item.global_issues),function(globalIssue){return _c('v-chip',{key:globalIssue.id,staticClass:"ma-1"},[_vm._v(" "+_vm._s(globalIssue.name)+" ")])}),1):[_vm._v("-")]],2),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Affairs.issues')}},[(item.issues && item.issues.length > 0)?_c('div',_vm._l((item.issues),function(issue){return _c('v-chip',{key:issue.id,staticClass:"ma-1"},[_vm._v(" "+_vm._s(issue.name)+" ")])}),1):[_vm._v("-")]],2),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Affairs.state')}},[_vm._v(" "+_vm._s(item.state ? item.state.name : '-')+" ")]),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Affairs.nextState')}},[_vm._v(" "+_vm._s(item.next_state ? item.next_state.name : '-')+" ")]),_c('core-affair-detail-info',{attrs:{"label":_vm.$t('Affairs.description')}},[_c('div',{staticClass:"text-line-clamp text-line-clamp-5",attrs:{"title":item.description},domProps:{"innerHTML":_vm._s(item.description ? _vm.$options.filters.nl2br(item.description) : '-')}})])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }