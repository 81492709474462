<template>
  <v-btn-toggle
    v-model="typeInternal"
    color="primary"
    mandatory
  >
    <v-btn
      v-for="(type, index) in enabledTypes"
      :key="index"
      :value="type"
      :disabled="loading"
      small
    >
      {{ $t(`Core.CalendarTypeToggle.types.${type}`) }}
    </v-btn>
  </v-btn-toggle>
</template>

<script>
export default {
  name: 'CalendarTypeToggle',
  props: {
    value: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    enabledTypes: {
      type: Array,
      default () {
        return ['day', 'week']
      },
    },
  },
  computed: {
    typeInternal: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
