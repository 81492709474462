import Vue from 'vue'

// Lib imports
import axios from 'axios'

const http = axios.create({
  withCredentials: true,
})

http.interceptors.response.use(null, function (error) {
  if (error.response.status === 401) {
    // TODO redirect to login
  }
  return Promise.reject(error)
})

Vue.prototype.$http = http

export default http
