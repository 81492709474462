import http from '@/plugins/axios'
import { api, perpareFilterParams } from '@/utils/vuex'
import { firstDayOfWeek, lastDayOfWeek } from '@/utils/dateHelper';

export default {
  async getEvents ({ commit, state }, { start = firstDayOfWeek(), end = lastDayOfWeek() } = {}) {
    const filterParams = perpareFilterParams(state.filters)
    commit('SET_LOADING', true)
    try {
      const res = await http.get(api('/events'),
      { params: { start, end, ...filterParams } })
      commit('SET_EVENTS', res.data)
    } catch (err) {
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    } finally {
      commit('SET_LOADING', false)
    }
  },
  async getFilterItems ({ commit, state }) {
    // Only load filter items once
    if (state.filterItemsFetched) {
      return
    }

    try {
      const res = await http.get(api('/events/calendar-filters'))
      commit('SET_FILTER_ITEMS', res.data.filters)
      commit('SET_FILTER_ITEMS_FETCHED', true)
    } catch (err) {
      commit('SET_FILTER_ITEMS_FETCHED', false)
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    }
  },
  async getICalUrl({commit, state}, token) {
    const filterParams = perpareFilterParams(state.filters)

    try {
      const res = await http.get(api('/events/icalurl'), { params: { ...filterParams, token: token } })
      return res.data;
    } catch (err) {
      commit('app/setSnack', { message: 'Error.fetchData', color: 'error', translate: true }, { root: true })
    }
  },
}
