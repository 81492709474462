<template>
  <div>
    <v-card
      class="comment mb-2"
      :disabled="loading"
      :loading="loading"
    >
      <v-card-title class="justify-space-between">
        <div>{{ comment.user ? `${comment.user.name} ${comment.user.surname}` : '' }}</div>
        <div
          :title="$t('Core.Comment.createdAt', { createdAt: $options.filters.formatDate(comment.created_at, $t('Common.dateTimeFormatLong')) })"
          class="body-2"
        >
          <v-icon
            small
            class="mr-1"
          >
            $vuetify.icons.clockRegular
          </v-icon>
          {{ dateFromNow(comment.created_at) }}
        </div>
      </v-card-title>
      <v-card-text>
        <div
          v-if="!edit"
          class="comment-message"
          :class="[comment.can_modify ? 'sent' : 'received']"
        >
          <div
            class="comment-message-content richeditor-content last-mb-0"
            v-html="$options.filters.nl2br(comment.content)"
          />
        </div>
        <v-form
          v-if="comment.can_modify && edit"
          @submit.prevent="saveEdit"
        >
          <CoreRichTextEditor
            v-model="editCommentItem.content"
            @empty="isEmpty => valid = !isEmpty"
          />
          <v-switch
            v-model="editCommentItem.is_public"
            @change="onIsPublicChange"
            :label="$t('Core.Comment.isPublicLabel')"
          />
          <v-select
            v-if="users && !editCommentItem.is_public"
            v-model="editCommentItem.allowed_users_ids"
            chips
            small-chips
            deletable-chips
            multiple
            :label="$t('Core.CommentSection.shareComments')"
            :items="users"
            item-text="name"
            item-value="id"
          />
          <v-select
            v-model="editCommentItem.note_type_id"
            :items="noteTypes"
            item-text="name"
            item-value="id"
            :clearable="true"
            :label="$t('Core.CommentSection.noteType')"
          />
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div
          v-if="comment.is_public && !edit"
          class="ml-2"
        >
          <v-icon
            small
            :title="$t('Core.Comment.isPublic')"
          >
            $vuetify.icons.globeAmericas
          </v-icon>
        </div>
        <div
          v-if="comment.note_type && !edit"
          class="ml-2"
        >
          <v-chip
            :color="comment.note_type.color || undefined"
            :text-color="comment.note_type.color ? getNoteTypeTextColor(comment.note_type.color) : undefined"
            small
          >
            {{ comment.note_type.name }}
          </v-chip>
        </div>
        <div
          v-if="comment.allowed_users.length > 0 && !edit"
          class="ml-2"
        >
          <v-icon
            small
            :title="allowedUsersTooltip"
          >
            $vuetify.icons.users
          </v-icon>
        </div>
        <v-btn
          v-if="edit"
          color="primary"
          type="submit"
          :disabled="!valid"
          text
          tile
          @click="saveEdit"
        >
          {{ $t('Core.Comment.save') }}
        </v-btn>
        <v-btn
          v-if="edit"
          text
          tile
          @click="cancelEdit"
        >
          {{ $t('Core.Comment.cancel') }}
        </v-btn>
        <div class="flex-grow-1" />
        <v-btn
          v-if="comment.can_modify && !edit"
          icon
          @click="enableEdit"
        >
          <v-icon
            :title="$t('Core.Comment.edit')"
            small
          >
            $vuetify.icons.edit
          </v-icon>
        </v-btn>
        <v-btn
          v-if="comment.can_modify && !edit"
          icon
          @click="onDeleteComment"
        >
          <v-icon
            :title="$t('Core.Comment.delete')"
            small
            color="error"
          >
            $vuetify.icons.trash
          </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="commentDeleteDialogOpen"
      persistent
      max-width="500px"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('Core.Comment.deleteTitle') }}
        </v-card-title>
        <v-card-text>
          {{ $t('Core.Comment.deleteText') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            tile
            @click="onCommentDeleteOkClick"
          >
            {{ $t('Core.Comment.deleteOk') }}
          </v-btn>
          <v-btn
            text
            tile
            @click="onCommentDeleteCancelClick"
          >
            {{ $t('Core.Comment.deleteCancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import { isEqual } from 'lodash-es'
import { DEFAULT_TEXT_COLOR, getContrastColor } from '@/utils/colorHelper'

export default {
  name: 'Comment',
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      valid: false,
      edit: false,
      loading: false,
      editCommentItem: null,
      commentDeleteDialogOpen: false,
    }
  },
  computed: {
    ...mapGetters('affairs', [
      'noteTypes',
    ]),
    ...mapGetters('auth', [
      'user',
      'otherUsers',
    ]),
    users () {
      return this.otherUsers.map((user) =>({
        id: user.id,
        name: `${user.name} ${user.surname} (${user.email})`,
      }))
    },
    allowedUsersTooltip () {
      return this.$t(
        'Core.Comment.allowedFor',
        {
          allowedUsers: this.comment.allowed_users.map((user) => user.id === this.user.id ? this.$t('Core.Comment.you') : `${user.name} ${user.surname}`).join(', '),
        },
      )
    },
  },
  methods: {
    ...mapActions('affairs', ['deleteComment', 'updateComment']),
    dateFromNow (date) {
      return date ? moment(date).fromNow() : date
    },
    onDeleteComment () {
      this.commentDeleteDialogOpen = true
    },
    enableEdit () {
      // clone comment
      this.editCommentItem = {
        ...this.comment,
        // add generated allowed_users_ids attribute to use as v-model value in select field
        allowed_users_ids: this.comment.allowed_users.map((allowedUser) => allowedUser.id),
      }
      this.edit = true
      this.valid = true // Set valid initially to true since we assume that the richeditor always has content when editing
    },
    async saveEdit () {
      // Only save comment if it has changed
      if (
        this.comment.content !== this.editCommentItem.content
        || this.comment.is_public !== this.editCommentItem.is_public
        || this.comment.note_type_id !== this.editCommentItem.note_type_id
        || !isEqual(this.comment.allowed_users.map((allowedUser) => allowedUser.id), this.editCommentItem.allowed_users_ids)
      ) {
        this.loading = true
        await this.updateComment({ comment: this.editCommentItem })
        this.loading = false
      }
      this.edit = false
    },
    cancelEdit () {
      this.editCommentItem = null
      this.edit = false
      this.valid = false
    },
    async onCommentDeleteOkClick () {
      this.commentDeleteDialogOpen = false
      this.loading = true
      await this.deleteComment({ comment: this.comment })
      this.loading = false
    },
    onCommentDeleteCancelClick () {
      this.commentDeleteDialogOpen = false
    },
    getNoteTypeTextColor (color) {
      return color ? getContrastColor(color) : DEFAULT_TEXT_COLOR
    },
    onIsPublicChange () {
      if (this.editCommentItem.is_public) {
        this.editCommentItem.allowed_users_ids = []
      }
    },
  },
}
</script>
