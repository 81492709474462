<template>
  <v-menu
    offset-y
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs"
        v-on="on"
      >
        <v-icon
          small
          class="mr-md-2"
        >
          $vuetify.icons.cog
        </v-icon>
        {{ $t('Dashboard.configure') }}
      </v-btn>
    </template>
    <v-list flat>
      <v-list-item-group
        :value="activeWidgetIds"
        multiple
      >
        <v-list-item
          v-for="widget in availableWidgets"
          :key="widget.id"
          :value="widget.id"
          :disabled="widget.loading"
          @click="toggleState(widget.id)"
        >
          <template v-slot:default="{ active }">
            <v-list-item-action>
              <v-checkbox :input-value="active"></v-checkbox>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ widget.title }}</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'DashboardConfigure',
  props: {
    availableWidgets: {
      type: Array,
      required: true,
    },
    activeWidgets: {
      type: Array,
      required: true,
    },
    timestamp: {
      type: String,
    },
  },
  methods: {
    ...mapActions('widgets', [
      'toggleWidget',
    ]),
    toggleState (widgetId) {
      this.toggleWidget({ widgetId })
    },
  },
  computed: {
    activeWidgetIds () {
      return this.activeWidgets.map((widget) => widget.id)
    },
  },
}
</script>
