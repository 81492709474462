<template>
  <v-card
    class="mx-auto"
    outlined
  >
    <v-card-actions v-if="editor" class="editor-actions">
      <v-btn
        small icon
        @click="editor.chain().toggleBold().focus().run()"
        :disabled="!editor.can().chain().toggleBold().run()"
        :input-value="editor.isActive('bold')"
      >
        <v-icon small>$vuetify.icons.bold</v-icon>
      </v-btn>
      <v-btn
        small icon
        @click="editor.chain().toggleUnderline().focus().run()"
        :disabled="!editor.can().chain().toggleUnderline().run()"
        :input-value="editor.isActive('underline')"
      >
        <v-icon small>$vuetify.icons.underline</v-icon>
      </v-btn>
      <v-btn
        small icon
        @click="editor.chain().toggleItalic().focus().run()"
        :disabled="!editor.can().chain().toggleItalic().run()"
        :input-value="editor.isActive('italic')"
      >
        <v-icon small>$vuetify.icons.italic</v-icon>
      </v-btn>
      <rich-text-editor-link-dialog
        :currentLink="editor.getAttributes('link').href"
        @input="value => editor.chain().extendMarkRange('link').setLink({ href: value, target: '_blank' }).focus().run()"
        @remove="() => editor.chain().extendMarkRange('link').unsetLink().focus().run()"
      >
        <template v-slot="{ on, attrs }">
          <v-btn
            small icon
            :disabled="
              (editor.state.selection.empty && !editor.getAttributes('link').target)
              || !editor.can().chain().toggleLink().run()
            "
            :input-value="editor.isActive('link')"
            :attrs="attrs"
            v-on="on"
          >
            <v-icon small>$vuetify.icons.link</v-icon>
          </v-btn>
        </template>
      </rich-text-editor-link-dialog>
      <v-btn
        small icon
        @click="editor.chain().toggleBulletList().focus().run()"
        :disabled="!editor.can().chain().toggleBulletList().run()"
        :input-value="editor.isActive('bulletList')"
      >
        <v-icon small>$vuetify.icons.uList</v-icon>
      </v-btn>
      <v-btn
        small icon
        @click="editor.chain().toggleOrderedList().focus().run()"
        :disabled="!editor.can().chain().toggleOrderedList().run()"
        :input-value="editor.isActive('orderedList')"
      >
        <v-icon small>$vuetify.icons.oList</v-icon>
      </v-btn>
    </v-card-actions>
    <v-divider/>
    <editor-content
      class="richeditor richeditor-content"
      :editor="editor"
    />
  </v-card>
</template>
<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import Document from '@tiptap/extension-document'
import Bold from '@tiptap/extension-bold'
import Italic from '@tiptap/extension-italic'
import Underline from '@tiptap/extension-underline'
import Text from '@tiptap/extension-text'
import Paragraph from '@tiptap/extension-paragraph'
import Link from '@tiptap/extension-link'
import BulletList from '@tiptap/extension-bullet-list'
import OrderedList from '@tiptap/extension-ordered-list'
import ListItem from '@tiptap/extension-list-item'
import RichTextEditorLinkDialog from './RichTextEditorLinkDialog.vue'

export default {
  name: 'RichTextEditor',
  components: {
    EditorContent,
    RichTextEditorLinkDialog,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      editor: null,
    }
  },

  watch: {
    value(value) {
      const isSame = this.editor.getHTML() === value
      if (isSame) return;

      this.editor.commands.setContent(value, false)
    },
  },

  mounted() {
    this.editor = new Editor({
      content: this.value,
      extensions: [
        Document,
        Bold,
        Italic,
        Underline,
        Text,
        Link,
        Paragraph,
        ListItem,
        BulletList,
        OrderedList,
      ],
      onUpdate: () => {
        this.$emit('empty', !this.editor.getText());
        this.$emit('input', this.editor.getHTML());
      },
      autofocus: false,
    })
  },
  beforeDestroy() {
    this.editor.destroy()
  },
}
</script>
