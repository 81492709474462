<template>
  <widgets-base
    :title="widget.title"
    :loading="loading"
  >
    <v-list
      class="latest-comments-list pa-0"
      two-line
    >
      <template v-if="loading">
        <v-list-item
          v-for="count in widget.item_count || 10"
          :key="count"
          class="pa-0"
        >
          <v-list-item-content class="pa-0">
            <v-skeleton-loader
              type="list-item-two-line"
            />
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-list-item
        v-else-if="comments.length > 0"
        v-for="comment in comments"
        :key="comment.id"
        :to="{ name: 'affair-detail', params: { id: comment.affair.id }, hash: COMMENT_SECTION_HASH }"
      >
        <v-list-item-content>
          <v-list-item-subtitle class="mb-1">
            <div class="d-flex flex-wrap justify-space-between">
              <div class="d-flex align-center mb-1">
                <v-icon
                  small
                  class="mr-1"
                >
                  $vuetify.icons.user
                </v-icon>
                {{ comment.user ? `${comment.user.name} ${comment.user.surname}` : '' }}
              </div>
              <div
                :title="$t('Dashboard.widgets.latestComments.createdAt', { createdAt: $options.filters.formatDate(comment.created_at, $t('Common.dateTimeFormatLong')) })"
                class="d-flex align-center mb-1"
              >
                <v-icon
                  small
                  class="mr-1"
                >
                  $vuetify.icons.clockRegular
                </v-icon>
                {{ dateFromNow(comment.created_at) }}
              </div>
            </div>
          </v-list-item-subtitle>
          <v-list-item-title class="mb-3 text-truncate">
            {{ comment.affair.title }}
          </v-list-item-title>
          <div
            class="comment-message"
            :class="[comment.can_modify ? 'sent' : 'received']"
          >
            <div class="comment-message-content">
              <div
                class="text-line-clamp text-line-clamp-3"
                v-html="$options.filters.nl2br(comment.content)"
              />
            </div>
          </div>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else>
        <v-list-item-content>
          {{ $t('Dashboard.widgets.latestComments.empty') }}
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </widgets-base>
</template>

<script>
import { mapActions } from 'vuex';
import moment from 'moment';
import { COMMENT_SECTION_HASH } from '@/utils/comments';

export default {
  name: 'LatestCommentsWidget',
  props: {
    widget: {
      type: Object,
      required: true,
    },
    comments: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  data () {
    return {
      COMMENT_SECTION_HASH,
    }
  },
  async mounted () {
    await this.getLatestComments(this.widget)
  },
  methods: {
    ...mapActions('widgets',
      [
        'getLatestComments',
      ],
    ),
    dateFromNow (date) {
      return date ? moment(date).fromNow() : date
    },
  },
}
</script>
