export default {
  affairs: {
    data: [],
  },
  affairError: null,
  affairLoading: true,
  options: {
    page: 1,
    itemsPerPage: 25,
    sortBy: ['submission_date'],
    sortDesc: [true],
  },
  loading: true,
  serverItemsLength: 0,
  itemsPerPageOptions: [25, 50, 100],
  filtersExpanded: undefined,
  filterItemsFetched: false,
  filters: [
    {
      name: 'subscribed',
      type: 'select',
      label: 'Core.Filters.subscriptionState.label',
      itemText: 'text',
      itemValue: 'value',
      items: [
        { value: true, text: 'Core.Filters.subscriptionState.subscribed', translate: true },
        { value: false, text: 'Core.Filters.subscriptionState.unsubscribed', translate: true },
      ],
      value: null,
      default: null,
    },
    {
      name: 'topic',
      type: 'multiselect',
      label: 'Core.Filters.topic.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'global_issue',
      type: 'multiselect',
      label: 'Core.Filters.globalIssue.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'issue',
      type: 'multiselect',
      label: 'Core.Filters.issue.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'state',
      type: 'multiselect',
      label: 'Core.Filters.state.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'next_state',
      type: 'multiselect',
      label: 'Core.Filters.nextState.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'submission_date',
      type: 'select',
      label: 'Core.Filters.submissionDate.label',
      itemText: 'text',
      itemValue: 'value',
      items: [
        { value: '1week', text: 'Core.Filters.submissionDate.1week', translate: true },
        { value: '2weeks', text: 'Core.Filters.submissionDate.2weeks', translate: true },
        { value: '1month', text: 'Core.Filters.submissionDate.1month', translate: true },
      ],
      value: '',
      default: '',
    },
    {
      name: 'session',
      type: 'multiselect',
      label: 'Core.Filters.session.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'type',
      type: 'multiselect',
      label: 'Core.Filters.type.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
    {
      name: 'political_level',
      type: 'multiselect',
      label: 'Core.Filters.politicalLevel.label',
      itemText: 'name',
      itemValue: 'id',
      items: [],
      value: [],
      default: [],
    },
  ],
  query: '',
  noteTypes: [],
}
