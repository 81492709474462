<template>
  <v-row dense>
    <v-col
      cols="12"
      sm="4"
      md="2"
    >
      <div class="font-weight-bold text-truncate">{{ labelFirstCol }}</div>
    </v-col>
    <v-col
      cols="12"
      sm="8"
      md="4"
    >
      <slot name="first-col" />
    </v-col>
    <v-col
      v-if="labelSecondCol"
      cols="12"
      sm="4"
      md="2"
    >
      <div class="font-weight-bold text-truncate">{{ labelSecondCol }}</div>
    </v-col>
    <v-col
      v-if="labelSecondCol"
      cols="12"
      sm="8"
      md="4"
    >
      <slot name="second-col" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AffairDetailInfoTwoColumn',
  props: {
    labelFirstCol: {
      type: String,
      default: '',
    },
    labelSecondCol: {
      type: String,
      default: '',
    },
  },
}
</script>
