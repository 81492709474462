<template>
  <v-app>
    <core-drawer />

    <core-toolbar />

    <core-view />

    <core-footer />

    <core-snackbar />

    <core-auth-loader />
  </v-app>
</template>

<script>
export default {
  metaInfo () {
    return {
      titleTemplate: this.$t('Common.titleTemplate'),
    }
  },
}
</script>
