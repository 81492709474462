<template>
  <widgets-base
    :title="widget.title"
    :loading="loading"
    :action="{ label: widget.action_label, to: { name: 'calendar' } }"
  >
    <template slot="actions">
      <div class="d-flex flex-wrap align-center">
        <core-calendar-today-button
          :loading="loading"
          @click="onTodayClick"
        />
        <core-calendar-type-toggle
          v-model="type"
          :loading="loading"
          class="ml-3"
        />
      </div>
    </template>
    <core-calendar
      :events="events"
      :loading="loading"
      :type="type"
      v-model="current"
      @range-change="onRangeChange"
    />
  </widgets-base>
</template>

<script>
import { mapActions } from 'vuex';
import { today } from '@/utils/dateHelper';

export default {
  name: 'CalendarWidget',
  props: {
    widget: {
      type: Object,
      required: true,
    },
    events: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  data: () => ({
    type: null, // initialize type with null to avoid second api call when switching type in mounted() hook
    current: today(),
  }),
  mounted () {
    this.type = this.$vuetify.breakpoint.xs ? 'day' : 'week'
  },
  methods: {
    ...mapActions('widgets', [
      'getCalendarEvents',
    ]),
    onTodayClick () {
      this.current = today()
    },
    onRangeChange (start, end) {
      this.getCalendarEvents({ start, end, widget: this.widget })
    },
  },
}
</script>
