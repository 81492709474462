<template>
  <v-navigation-drawer
    id="app-drawer"
    v-model="drawer"
    app
    floating
  >
    <router-link :to="{ name: defaultRouteName }">
      <img
        src="/images/politmonitoring-logo.png"
        class="logo"
      >
    </router-link>
    <v-list>
      <v-list-item
        v-for="(link, i) in links"
        :key="i"
        :to="link.to"
        :exact="link.exact"
        color="primary"
      >
        <v-list-item-icon>
          <v-icon>{{ link.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title
          v-text="link.text"
        />
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapMutations } from 'vuex'
import { defaultRouteName } from '@/router'
import { permissions } from '@/utils/permissions'
export default {
  data: function () {
    const links = [
      {
        to: { name: 'dashboard' },
        exact: true,
        icon: '$vuetify.icons.tachometerAlt',
        text: this.$t('Core.Drawer.dashboard'),
      },
      {
        to: { name: 'affairs' },
        exact: false,
        icon: '$vuetify.icons.circle',
        text: this.$t('Core.Drawer.affairs'),
      },
      {
        to: { name: 'calendar' },
        exact: false,
        icon: '$vuetify.icons.calendarAlt',
        text: this.$t('Core.Drawer.calendar'),
      },
      {
        to: { name: 'events-future' },
        exact: false,
        icon: '$vuetify.icons.calendarMinus',
        text: this.$t('Core.Drawer.eventsFuture'),
      },
      {
        to: { name: 'events-past' },
        exact: false,
        icon: '$vuetify.icons.newspaper',
        text: this.$t('Core.Drawer.eventsPast'),
      },
    ]
    if (this.hasPermission(permissions.ISSUES) || this.hasPermission(permissions.GLOBAL_ISSUES)) {
      links.push({
        to: { name: 'issues' },
        icon: '$vuetify.icons.tags',
        text: this.$t('Core.Drawer.issues'),
      })
    }
    return {
      links,
      defaultRouteName: defaultRouteName,
    }
  },
  computed: {
    drawer: {
      get () {
        return this.$store.state.app.drawer
      },
      set (drawer) {
        this.setDrawer(drawer)
      },
    },
  },
  methods: {
    ...mapMutations('app', ['setDrawer']),
  },
}
</script>
