<template>
  <v-row dense>
    <v-col
      cols="12"
      sm="4"
      md="2"
    >
      <div class="font-weight-bold text-truncate">{{ label }}</div>
    </v-col>
    <v-col
      cols="12"
      sm="8"
      md="10"
    >
      <slot />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AffairDetailInfo',
  props: {
    label: {
      type: String,
      default: '',
    },
  },
}
</script>
