<template>
  <v-dialog
    v-model="loading"
    persistent
    fullscreen
    transition="fade-transition"
  >
    <v-container
      class="justify-center align-center flex-column"
      fluid
      fill-height
    >
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
        class="mb-3"
      />
      <p>{{ $t('Core.AuthLoader.loading') }}</p>
    </v-container>
  </v-dialog>
</template>

<script>
export default {
  name: 'AuthLoader',
  computed: {
    loading () {
      return this.$store.state.auth.loading
    },
  },
}
</script>
