<template>
  <v-app>
    <router-view />
    <core-footer />
    <core-snackbar />
  </v-app>
</template>

<script>
export default {
  metaInfo () {
    return {
      titleTemplate: this.$t('Common.titleTemplate'),
    }
  },
}
</script>
